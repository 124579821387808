// Chakra imports
import {
  Portal,
  useDisclosure,
  Stack,
  Box,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import Footer from "components/Footer/Footer";
import { LogoDark, LogoLight } from "components/Icons/Icons";
// Layout components
import AdminNavbar from "components/Navbars/AdminNavbar";
import Sidebar from "components/Sidebar/Sidebar";
import React, { useState, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "routes";
// Custom components
import MainPanel from "components/Layout/MainPanel";
import PanelContainer from "components/Layout/PanelContainer";
import PanelContent from "components/Layout/PanelContent";
import bgAdmin from "assets/img/admin-background.jpg";
import { USER_API_URL } from "../config/apiUrl";
import makeRequest from "apis/axiosMethods";
import OAuth2RedirectHandler from "user/oauth2/OAuth2RedirectHandler";
import { utils } from "utils";

const userId = localStorage.getItem("userId");

export default function Admin(props) {
  const { ...rest } = props;
  const [activeRoute, setActiveRoute] = useState("");
  const [profileDetails, setProfileDetails] = useState({});
  const { colorMode } = useColorMode();
  const toast = useToast();
  const fixed = false;

  useEffect(() => {
    if (!userId) return;
    const fetchProfileDetails = async () => {
      const profile_url = `${USER_API_URL}/${userId}`;
      let response = await makeRequest("get", profile_url);
      if (response.data) {
        setProfileDetails(response.data);
      } else {
        const capturedError = utils.extractHttpError(response);
        if (capturedError !== "") {
          toast({
            title: "Error fetching data",
            description: JSON.stringify(capturedError.data),
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      }
    };
    fetchProfileDetails();
  }, []);
  // functions for changing the states from components
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  // This changes navbar state(fixed or not)
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === "account") {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return <Route path={prop.path} element={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  const onClickSideBarIcon = (route) => {
    setActiveRoute(route);
  };

  const { onOpen } = useDisclosure();

  // Chakra Color Mode
  return (
    <Box>
      <OAuth2RedirectHandler />
      <Box
        minH="40vh"
        w="100%"
        position="absolute"
        bgImage={colorMode === "light" ? bgAdmin : "none"}
        bg={colorMode === "light" ? bgAdmin : "navy.900"}
        bgSize="cover"
        top="0"
      />
      <Box w="100%" minW={"250px"}>
        <Sidebar
          routes={routes}
          logo={
            <Stack
              direction="row"
              spacing="12px"
              align="center"
              justify="center"
            >
              {colorMode === "dark" ? (
                <LogoLight w="74px" h="27px" />
              ) : (
                <LogoDark w="74px" h="27px" />
              )}
            </Stack>
          }
          activeRoute={activeRoute}
          onClickRoute={onClickSideBarIcon}
          profileDetails={profileDetails}
          {...rest}
        />
      </Box>
      <MainPanel
        w={{
          base: "100%",
          xl: "calc(100% - 275px)",
        }}
        minWidth="500px"
      >
        <Portal>
          <AdminNavbar
            onOpen={onOpen}
            brandText={getActiveRoute(routes)}
            secondary={getActiveNavbar(routes)}
            fixed={fixed}
            profileDetails={profileDetails}
            {...rest}
          />
        </Portal>
        <PanelContent>
          <PanelContainer>
            <Routes>
              {getRoutes(routes)}
              <Route
                path="/dashboard"
                element={<Navigate replace to="/admin/dashboard" />}
              />
            </Routes>
          </PanelContainer>
        </PanelContent>
        <Footer />
      </MainPanel>
    </Box>
  );
}
