import { FaSignOutAlt } from "react-icons/fa";
// Chakra Imports
import {
  Icon,
  Button,
  Flex,
  Menu,
  Link,
  MenuButton,
  MenuItem,
  MenuList,
  Avatar,
  Center,
  MenuDivider,
  useColorMode,
  useToast,
  Stack,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
import React from "react";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import routes from "routes.js";
import { LogoLight } from "components/Icons/Icons";
import { LogoDark } from "components/Icons/Icons";
import { ACCESS_TOKEN } from "config/apiUrl";
// import { useNavigate } from "react-router-dom";

export default function HeaderLinks(props) {
  const {
    variant,
    children,
    fixed,
    scrolled,
    secondary,
    onOpen,
    profileDetails,
    ...rest
  } = props;

  const { colorMode, toggleColorMode } = useColorMode();
  const toast = useToast();
  // let navigate = useNavigate();

  function goToLogin() {
    // navigate("/login");
    window.location.href = window.location.origin + "#/login";
  }

  const logout = () => {
    localStorage.removeItem("userId");
    localStorage.removeItem(ACCESS_TOKEN);
    toast({
      title: "You've been successfully logged out.",
      description: "We can't wait to see you again.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    goToLogin();
  };

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      <SidebarResponsive
        hamburgerColor={"white"}
        logo={
          <Stack direction="row" spacing="12px" align="center" justify="center">
            {colorMode === "dark" ? (
              <LogoLight w="74px" h="27px" />
            ) : (
              <LogoDark w="74px" h="27px" />
            )}
          </Stack>
        }
        colorMode={colorMode}
        secondary={props.secondary}
        routes={routes}
        profileDetails={profileDetails}
        {...rest}
      />
      <Menu>
        <MenuButton
          as={Button}
          rounded={"full"}
          variant={"link"}
          cursor={"pointer"}
          minW={0}
        >
          <Avatar size={"sm"} src={profileDetails.imageUrl} />
        </MenuButton>
        <MenuList alignItems={"center"}>
          <br />
          <Center>
            <Avatar size={"2xl"} src={profileDetails.imageUrl} />
          </Center>
          <br />
          <Center>
            <p>{profileDetails.name}</p>
          </Center>
          <br />
          <MenuDivider />
          <MenuItem>
            <Link href="#/admin/profile">
              <ProfileIcon />
              {" My Profile"}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link href="#/admin/settings">
              <SettingsIcon />
              {" Account Settings"}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link onClick={toggleColorMode}>
              {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
              {" Toggle Dark/Light"}
            </Link>
          </MenuItem>
          <MenuItem>
            <Link onClick={logout}>
              <Icon as={FaSignOutAlt} />
              {" Logout"}
            </Link>
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
}
