import { useEffect } from "react";
import { ACCESS_TOKEN } from "../../config/apiUrl";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const OAuth2RedirectHandler = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Function to extract query params and return them as an object
    const getQueryParams = (query) => {
      return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split("&")
            .reduce((params, param) => {
              let [key, value] = param.split("=");
              params[key] = value
                ? decodeURIComponent(value.replace(/\+/g, " "))
                : "";
              return params;
            }, {})
        : {};
    };

    const fullPath = window.location.href;
    if (fullPath.includes("/login/oauth2/redirect?")) {
      const queryParams = getQueryParams(window.location.search);
      const token = queryParams["token"];
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          localStorage.setItem(ACCESS_TOKEN, token);
          localStorage.setItem("userId", decodedToken["sub"]);
          // Now, handling the redirect to the hash part
          const hashIndex = fullPath.indexOf("#");
          if (hashIndex !== -1) {
            // Extract and navigate to the hash part
            const pathAfterHash = fullPath.substring(hashIndex + 2);
            window.location.href =
              window.location.origin + "#/" + pathAfterHash;
          } else {
            // If no hash is found, navigate to a default location
            window.location.href = window.location.origin + "#/admin/dashboard";
          }
        } catch (error) {
          console.error("Failed to decode token or set local storage:", error);
          navigate("/error");
        }
      } else {
        // Redirect to a default or error route as fallback
        navigate("/error");
      }
    }
  }, [navigate]);

  return null;
};

export default OAuth2RedirectHandler;
