// Chakra imports
import {
  Box,
  Flex,
  Grid,
  Spinner,
  Text,
  useToast,
  useColorMode,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import BarChart from "components/Charts/BarChart";
import LineChart from "components/Charts/LineChart";
import React, { useState, useEffect } from "react";
import GradesGrid from "./GradesGrid";
import {
  SEMESTER_API_URL,
  USER_API_URL,
  COURSE_API_URL,
  EDUCATION_API_URL,
  COLLEGE_API_URL,
  DEGREE_API_URL,
  BRANCH_API_URL,
} from "../../config/apiUrl";

import makeRequest from "apis/axiosMethods";
import { utils } from "utils";
import {
  lineChartOptions,
  barChartOptions,
  horizontalBarChartOptions,
} from "variables/charts";
import HorizontalBarChart from "components/Charts/HorizontalBarChart";
import ShowEmptyDataAlert from "views/Pages/ShowEmptyDataAlert";

const userId = localStorage.getItem("userId");

export default function Dashboard() {
  const { colorMode } = useColorMode();

  const [isLoading, setIsLoading] = useState(false);
  const [profileDetails, setProfileDetails] = useState({});
  const [transcriptSemestersData, setTranscriptSemestersData] = useState([]);
  const [educationBasicDetails, setEducationBasicDetails] = useState({});

  const [gpaChartDetails, setGpaChartDetails] = useState({});
  const [yearCreditChartDetails, setYearCreditChartDetails] = useState({});
  const [creditChartDetails, setCreditChartDetails] = useState({});
  const [semesterTypeChartDetails, setSemesterTypeChartDetails] = useState({});

  const toast = useToast();
  useEffect(() => {
    if (!userId) return;
    const fetchBasicDetails = async () => {
      try {
        let capturedError = "";
        const educationBasicDetails = {};
        setIsLoading(true);
        try {
          const userEducationResults = await Promise.all([
            makeRequest("get", `${USER_API_URL}/${userId}`),
            makeRequest("get", `${EDUCATION_API_URL}/user/${userId}`),
            makeRequest("get", `${SEMESTER_API_URL}/user/${userId}`),
          ]);
          const profileDetails = userEducationResults[0].data || {};
          const educationData = userEducationResults[1].data || {};
          const semesterData = userEducationResults[2].data || [];

          educationBasicDetails.degreeGradeScale =
            utils.getDegreeGradeScaleNumeric(educationData.degreeGradeScale);
          educationBasicDetails.degreeMode = educationData.degreeMode;
          educationBasicDetails.startDate = educationData.startDate;
          educationBasicDetails.endDate = educationData.endDate;
          educationBasicDetails.totalCredits = educationData.totalCredits;
          educationBasicDetails.ongoing = educationData.ongoing;

          const collegeId = educationData.collegeId;
          const branchId = educationData.branchId;
          const degreeId = educationData.degreeId;
          const [college, branch, degree] = await Promise.all([
            makeRequest("get", `${COLLEGE_API_URL}/${collegeId}`),
            makeRequest("get", `${BRANCH_API_URL}/${branchId}`),
            makeRequest("get", `${DEGREE_API_URL}/${degreeId}`),
          ]);

          educationBasicDetails.collegeName = college?.data?.name;
          educationBasicDetails.collegeWebsite = college?.data?.website;
          educationBasicDetails.branchName = branch?.data?.name;
          educationBasicDetails.degreeName = degree?.data?.name;

          let courseResults = await Promise.all(
            semesterData.map((semester) =>
              makeRequest(
                "get",
                `${COURSE_API_URL}/semester/${semester.semesterId}`
              )
            )
          );
          courseResults = courseResults
            .filter((result) => result.data?.length > 0)
            .map((result) => result.data);
          let transcriptSemestersData = semesterData.map((semester, index) => ({
            ...semester,
            courses:
              index < courseResults.length
                ? courseResults[index].map((course) => ({
                    code: course.code,
                    name: course.name,
                    totalCredits: course.totalCredits,
                    obtainedGrade: course.obtainedGrade,
                  }))
                : [],
          }));
          transcriptSemestersData = transcriptSemestersData.map(
            (semester, index) => {
              const newSpi = utils.getSpiForSemester(
                semester,
                educationBasicDetails.degreeGradeScale
              );

              const newCpi = utils.getCpiSoFarForSemester(
                transcriptSemestersData.slice(0, index + 1),
                educationBasicDetails.degreeGradeScale
              );

              return {
                ...semester,
                obtainedSpi: isNaN(newSpi) ? semester.obtainedSpi : newSpi,
                obtainedCpi: isNaN(newCpi) ? semester.obtainedCpi : newCpi,
              };
            }
          );
          setProfileDetails(profileDetails);
          setTranscriptSemestersData(transcriptSemestersData);
          setEducationBasicDetails(educationBasicDetails);
        } catch (error) {
          capturedError = utils.extractHttpError(error);
        }

        if (capturedError !== "") {
          toast({
            title: "Error fetching data",
            description: JSON.stringify(capturedError.data),
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchBasicDetails();
  }, []);

  useEffect(() => {
    if (!userId) return;
    if (transcriptSemestersData.length === 0) return;
    const categories = transcriptSemestersData.map((semester) => semester.name);
    const gpaDataChartOptions = {
      ...lineChartOptions,
      xaxis: {
        ...lineChartOptions.xaxis,
        categories: categories,
      },
    };

    const gpaData = [
      {
        name: "Cumulative GPA",
        data: transcriptSemestersData.map((semester) => semester.obtainedCpi),
      },
      {
        name: "Semester GPA",
        data: transcriptSemestersData.map((semester) => semester.obtainedSpi),
      },
    ];
    setGpaChartDetails({
      chartData: gpaData,
      chartOptions: gpaDataChartOptions,
    });
  }, [transcriptSemestersData]);

  useEffect(() => {
    if (!userId) return;
    if (transcriptSemestersData.length === 0) return;
    let endDates = transcriptSemestersData.map((semester) =>
      new Date(semester.endDate).getFullYear()
    );
    const categories = utils.removeDuplicates(endDates);
    const yearCreditOptions = {
      ...barChartOptions,
      xaxis: {
        ...barChartOptions.xaxis,
        categories: categories.map((item) => item.toString()), //since dates are still integers
      },
    };

    let totalCreditsData = new Array(categories.length).fill(0);
    let totalObtainedCreditsData = new Array(categories.length).fill(0);
    transcriptSemestersData.forEach((semester) => {
      const semesterEndYear = new Date(semester.endDate).getFullYear();
      const yearIndex = categories.indexOf(semesterEndYear);
      if (yearIndex !== -1) {
        totalCreditsData[yearIndex] += utils.getTotalCredits(semester);
      }
    });
    transcriptSemestersData.forEach((semester) => {
      const semesterEndYear = new Date(semester.endDate).getFullYear();
      const yearIndex = categories.indexOf(semesterEndYear);
      if (yearIndex !== -1) {
        totalObtainedCreditsData[yearIndex] +=
          utils.getTotalObtainedCredits(semester);
      }
    });
    const yearCreditData = [
      {
        name: "Total Credits",
        data: totalCreditsData.map((item) => item.toFixed(2)),
      },
      {
        name: "Obtained Credits",
        data: totalObtainedCreditsData.map((item) => item.toFixed(2)),
      },
    ];
    setYearCreditChartDetails({
      chartData: yearCreditData,
      chartOptions: yearCreditOptions,
    });
  }, [transcriptSemestersData]);

  useEffect(() => {
    if (!userId) return;
    if (transcriptSemestersData.length === 0) return;
    const categories = transcriptSemestersData.map((semester) => semester.name);
    const creditDataChartOptions = {
      ...barChartOptions,
      xaxis: {
        ...barChartOptions.xaxis,
        categories: categories,
      },
    };

    const creditData = [
      {
        name: "Total Credits",
        data: transcriptSemestersData.map((semester) =>
          utils.getTotalCredits(semester).toFixed(2)
        ),
      },
      {
        name: "Obtained Credits",
        data: transcriptSemestersData.map((semester) =>
          utils.getTotalObtainedCredits(semester).toFixed(2)
        ),
      },
    ];
    setCreditChartDetails({
      chartData: creditData,
      chartOptions: creditDataChartOptions,
    });
  }, [transcriptSemestersData]);

  useEffect(() => {
    if (!userId) return;
    if (transcriptSemestersData.length === 0) return;
    let semesterTypes = transcriptSemestersData.map(
      (semester) => semester.semesterType
    );
    const categories = utils.removeDuplicates(semesterTypes);
    const yearCreditOptions = {
      ...horizontalBarChartOptions,
      xaxis: {
        ...horizontalBarChartOptions.xaxis,
        categories: categories,
      },
    };

    let totalCreditsData = new Array(categories.length).fill(0);
    let totalObtainedCreditsData = new Array(categories.length).fill(0);
    transcriptSemestersData.forEach((semester) => {
      const semesterType = semester.semesterType;
      const typeIndex = categories.indexOf(semesterType);
      if (typeIndex !== -1) {
        totalCreditsData[typeIndex] += utils.getTotalCredits(semester);
      }
    });
    transcriptSemestersData.forEach((semester) => {
      const semesterType = semester.semesterType;
      const typeIndex = categories.indexOf(semesterType);
      if (typeIndex !== -1) {
        totalObtainedCreditsData[typeIndex] +=
          utils.getTotalObtainedCredits(semester);
      }
    });
    const yearCreditData = [
      {
        name: "Total Credits",
        data: totalCreditsData.map((item) => item.toFixed(2)),
      },
      {
        name: "Obtained Credits",
        data: totalObtainedCreditsData.map((item) => item.toFixed(2)),
      },
    ];
    setSemesterTypeChartDetails({
      chartData: yearCreditData,
      chartOptions: yearCreditOptions,
    });
  }, [transcriptSemestersData]);

  if (!userId) {
    window.location.href = window.location.origin + "#/login";
    return null;
  }
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <GradesGrid
        educationBasicDetails={educationBasicDetails}
        transcriptSemestersData={transcriptSemestersData}
      />
      {isLoading ? (
        <Flex justify="center" align="center" height="60vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <>
          {educationBasicDetails && transcriptSemestersData.length > 0 ? (
            <Grid
              templateColumns={{ sm: "1fr", md: "1fr 1fr", lg: "1fr 1fr" }}
              templateRows={{ lg: "repeat(2, auto)" }}
              gap="20px"
            >
              <Card
                bg={
                  colorMode === "dark"
                    ? "navy.800"
                    : "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                }
                p="0px"
                maxW={{ sm: "320px", md: "100%" }}
              >
                <Flex direction="column" mb="40px" p="28px 0px 0px 22px">
                  <Text color="#fff" fontSize="lg" fontWeight="bold" mb="6px">
                    {"Trend of CGPA and SPI"}
                  </Text>
                </Flex>
                <Box minH="300px">
                  <LineChart
                    chartData={gpaChartDetails.chartData}
                    chartOptions={gpaChartDetails.chartOptions}
                  />
                </Box>
              </Card>
              <Card
                bg={
                  colorMode === "dark"
                    ? "navy.800"
                    : "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                }
                p="0px"
                maxW={{ sm: "320px", md: "100%" }}
              >
                <Flex direction="column" mb="40px" p="28px 0px 0px 22px">
                  <Text color="#fff" fontSize="lg" fontWeight="bold" mb="6px">
                    {"Credit division year wise"}
                  </Text>
                </Flex>
                <Box minH="300px">
                  <BarChart
                    chartData={yearCreditChartDetails.chartData}
                    chartOptions={yearCreditChartDetails.chartOptions}
                  />
                </Box>
              </Card>
              <Card
                bg={
                  colorMode === "dark"
                    ? "navy.800"
                    : "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                }
                p="0px"
                maxW={{ sm: "320px", md: "100%" }}
              >
                <Flex direction="column" mb="40px" p="28px 0px 0px 22px">
                  <Text color="#fff" fontSize="lg" fontWeight="bold" mb="6px">
                    {"Credit division based on semester"}
                  </Text>
                </Flex>
                <Box minH="300px">
                  <BarChart
                    chartData={creditChartDetails.chartData}
                    chartOptions={creditChartDetails.chartOptions}
                  />
                </Box>
              </Card>
              <Card
                bg={
                  colorMode === "dark"
                    ? "navy.800"
                    : "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                }
                p="0px"
                maxW={{ sm: "320px", md: "100%" }}
              >
                <Flex direction="column" mb="40px" p="28px 0px 0px 22px">
                  <Text color="#fff" fontSize="lg" fontWeight="bold" mb="6px">
                    {"Credit division based on semester type"}
                  </Text>
                </Flex>
                <Box minH="300px">
                  <HorizontalBarChart
                    chartData={semesterTypeChartDetails.chartData}
                    chartOptions={semesterTypeChartDetails.chartOptions}
                  />
                </Box>
              </Card>
            </Grid>
          ) : (
            <ShowEmptyDataAlert
              message={"No data found. Start by going to the grade-planner."}
            />
          )}
        </>
      )}
    </Flex>
  );
}
