// Chakra imports
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Spacer,
  Spinner,
  useToast,
} from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";
import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import GradesGrid from "./GradesGrid";
import {
  SEMESTER_API_URL,
  USER_API_URL,
  COURSE_API_URL,
  EDUCATION_API_URL,
  COLLEGE_API_URL,
  DEGREE_API_URL,
  BRANCH_API_URL,
} from "../../config/apiUrl";

import makeRequest from "apis/axiosMethods";
import { utils } from "utils";
import ShowEmptyDataAlert from "views/Pages/ShowEmptyDataAlert";

const userId = localStorage.getItem("userId");

export default function Transcript() {
  const tableRowColor = useColorModeValue("#F7FAFC", "navy.900");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const textTableColor = useColorModeValue("gray.500", "white");

  const [isLoading, setIsLoading] = useState(false);
  const [profileDetails, setProfileDetails] = useState({});
  const [transcriptSemestersData, setTranscriptSemestersData] = useState([]);
  const [educationBasicDetails, setEducationBasicDetails] = useState({});

  const toast = useToast();

  useEffect(() => {
    if (!userId) return;
    const fetchBasicDetails = async () => {
      try {
        let capturedError = "";
        const educationBasicDetails = {};
        setIsLoading(true);
        try {
          const userEducationResults = await Promise.all([
            makeRequest("get", `${USER_API_URL}/${userId}`),
            makeRequest("get", `${EDUCATION_API_URL}/user/${userId}`),
            makeRequest("get", `${SEMESTER_API_URL}/user/${userId}`),
          ]);
          const profileDetails = userEducationResults[0].data || {};
          const educationData = userEducationResults[1].data || {};
          const semesterData = userEducationResults[2].data || [];

          educationBasicDetails.degreeGradeScale =
            utils.getDegreeGradeScaleNumeric(educationData.degreeGradeScale);
          educationBasicDetails.degreeMode = educationData.degreeMode;
          educationBasicDetails.startDate = educationData.startDate;
          educationBasicDetails.endDate = educationData.endDate;
          educationBasicDetails.totalCredits = educationData.totalCredits;
          educationBasicDetails.ongoing = educationData.ongoing;

          const collegeId = educationData.collegeId;
          const branchId = educationData.branchId;
          const degreeId = educationData.degreeId;
          const [college, branch, degree] = await Promise.all([
            makeRequest("get", `${COLLEGE_API_URL}/${collegeId}`),
            makeRequest("get", `${BRANCH_API_URL}/${branchId}`),
            makeRequest("get", `${DEGREE_API_URL}/${degreeId}`),
          ]);

          educationBasicDetails.collegeName = college?.data?.name;
          educationBasicDetails.collegeWebsite = college?.data?.website;
          educationBasicDetails.branchName = branch?.data?.name;
          educationBasicDetails.degreeName = degree?.data?.name;

          let courseResults = await Promise.all(
            semesterData.map((semester) =>
              makeRequest(
                "get",
                `${COURSE_API_URL}/semester/${semester.semesterId}`
              )
            )
          );
          courseResults = courseResults
            .filter((result) => result.data?.length > 0)
            .map((result) => result.data);
          let transcriptSemestersData = semesterData.map((semester, index) => ({
            ...semester,
            courses:
              index < courseResults.length
                ? courseResults[index].map((course) => ({
                    code: course.code,
                    name: course.name,
                    totalCredits: course.totalCredits,
                    obtainedGrade: course.obtainedGrade,
                  }))
                : [],
          }));
          transcriptSemestersData = transcriptSemestersData.map(
            (semester, index) => {
              const newSpi = utils.getSpiForSemester(
                semester,
                educationBasicDetails.degreeGradeScale
              );

              const newCpi = utils.getCpiSoFarForSemester(
                transcriptSemestersData.slice(0, index + 1),
                educationBasicDetails.degreeGradeScale
              );

              return {
                ...semester,
                obtainedSpi: isNaN(newSpi) ? semester.obtainedSpi : newSpi,
                obtainedCpi: isNaN(newCpi) ? semester.obtainedCpi : newCpi,
              };
            }
          );
          setProfileDetails(profileDetails);
          setTranscriptSemestersData(transcriptSemestersData);
          setEducationBasicDetails(educationBasicDetails);
        } catch (error) {
          capturedError = utils.extractHttpError(error);
        }

        if (capturedError !== "") {
          toast({
            title: "Error fetching data",
            description: JSON.stringify(capturedError.data),
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchBasicDetails();
  }, []);

  const gradeColor = (grade) => {
    if (grade >= 0 && grade <= 5) return "red";
    else if (grade >= 6 && grade <= 8) return "yellow";
    else return "green";
  };

  const semesterGradeHeader = ["Code", "Name", "Credits", "Grade"];

  const semesterAggregateHeader = [
    "Semester No",
    "Semester Start Date",
    "Semester End Date",
    "SPI",
    "CPI So Far",
  ];
  const semesterGradeData = (semester) => [
    semesterGradeHeader,
    ...semester.courses.map(({ code, name, totalCredits, obtainedGrade }) => [
      code,
      name,
      totalCredits,
      obtainedGrade,
    ]),
    [],
  ];

  const semesterAggregateDetails = (semester, index) => [
    semesterAggregateHeader,
    [
      index + 1,
      semester.startDate,
      semester.endDate,
      semester.obtainedSpi,
      utils.getCpiSoFarForSemester(
        transcriptSemestersData.slice(0, index + 1),
        educationBasicDetails.degreeGradeScale
      ),
    ],
    [],
  ];

  const allSemesterDetails = [];
  transcriptSemestersData.forEach((semester, index) => {
    allSemesterDetails.push(...semesterGradeData(semester));
    allSemesterDetails.push(...semesterAggregateDetails(semester, index));
  });

  const transcriptBasicDetails = [
    [
      "Name",
      "College Name",
      "Website",
      "Degree",
      "Branch",
      "Grade Scale",
      "Degree Start Date",
      "Degree End Date",
      "Total Credits for Degree",
      "Is Degree Complete",
    ],
    [
      profileDetails?.name,
      educationBasicDetails?.collegeName,
      educationBasicDetails?.collegeWebsite,
      educationBasicDetails?.degreeName,
      educationBasicDetails?.branchName,
      educationBasicDetails?.degreeGradeScale,
      educationBasicDetails?.startDate,
      educationBasicDetails?.endDate,
      educationBasicDetails?.totalCredits,
      educationBasicDetails?.ongoing ? "NO" : "YES",
    ],
    [],
  ];

  const accordionItemsJxs = transcriptSemestersData.map((semester, index) => {
    return (
      <React.Fragment key={semester.semesterId}>
        <AccordionItem borderColor={"gray"}>
          <AccordionButton
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={4}
          >
            <Text fontSize="md">{semester.name}</Text>
            <Spacer />
            <Flex direction="column" align="right">
              <Button variant="primary" maxH="30px" fontSize="xs">
                <CSVLink
                  className="downloadbtn"
                  filename={semester.name + "-grade-card.csv"}
                  data={[
                    ...transcriptBasicDetails,
                    ...semesterGradeData(semester),
                    ...semesterAggregateDetails(semester, index),
                  ]}
                >
                  {"Export to CSV"}
                </CSVLink>
              </Button>
            </Flex>
            <ChevronDownIcon fontSize="24px" />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <Text color="gray.600">
              <Box overflow={{ sm: "scroll", lg: "hidden" }}>
                <Table id={semester.name}>
                  <Thead>
                    <Tr bg={tableRowColor}>
                      <Th color="gray.400" borderColor={borderColor}>
                        {"Course"}
                      </Th>
                      <Th color="gray.400" borderColor={borderColor}>
                        {"Course name"}
                      </Th>
                      <Th color="gray.400" borderColor={borderColor}>
                        {"Total Credits"}
                      </Th>
                      <Th color="gray.400" borderColor={borderColor}>
                        {"Obtained Grade"}
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {semester.courses.map((course, index, arr) => {
                      return (
                        <Tr key={course.code}>
                          <Td
                            color={textTableColor}
                            fontSize="sm"
                            border={index === arr.length - 1 ? "none" : null}
                            borderColor={borderColor}
                          >
                            {course.code}
                          </Td>
                          <Td
                            color={textTableColor}
                            fontSize="sm"
                            fontWeight="bold"
                            borderColor={borderColor}
                            border={index === arr.length - 1 ? "none" : null}
                          >
                            {course.name}
                          </Td>

                          <Td
                            color={textTableColor}
                            fontSize="sm"
                            border={index === arr.length - 1 ? "none" : null}
                            borderColor={borderColor}
                          >
                            {course.totalCredits}
                          </Td>
                          <Td
                            color={textTableColor}
                            fontSize="sm"
                            border={index === arr.length - 1 ? "none" : null}
                            borderColor={borderColor}
                          >
                            <Flex align="center">
                              <Text
                                color={textTableColor}
                                fontWeight="bold"
                                fontSize="sm"
                                me="12px"
                              >{`${course.obtainedGrade}`}</Text>
                              <Progress
                                size="xs"
                                colorScheme={gradeColor(course.obtainedGrade)}
                                value={course.obtainedGrade * 10}
                                minW="120px"
                              />
                            </Flex>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
            </Text>
          </AccordionPanel>
        </AccordionItem>
      </React.Fragment>
    );
  });

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <GradesGrid
        educationBasicDetails={educationBasicDetails}
        transcriptSemestersData={transcriptSemestersData}
      />
      <Flex
        direction="column"
        maxW={{ sm: "320px", md: "100%" }}
        w="100%"
        justify={"center"}
        bg={useColorModeValue("gray.50", "gray.800")}
        borderRadius={"10px"}
      >
        {isLoading ? (
          <Flex justify="center" align="center" height="60vh">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <>
            {educationBasicDetails && transcriptSemestersData.length > 0 ? (
              <>
                <Accordion allowMultiple>{accordionItemsJxs}</Accordion>
                <Flex minWidth="max-content" alignItems="center" gap="2">
                  <Spacer />
                  <ButtonGroup gap="2" mt="30px">
                    <Button variant="primary" fontSize="xs">
                      <CSVLink
                        className="downloadbtn"
                        filename={"grade-card.csv"}
                        data={[
                          ...transcriptBasicDetails,
                          ...allSemesterDetails,
                        ]}
                      >
                        {"Export All As Single CSV"}
                      </CSVLink>
                    </Button>
                  </ButtonGroup>
                </Flex>
              </>
            ) : (
              <ShowEmptyDataAlert
                message={"No data found. Start by going to the grade-planner."}
              />
            )}
          </>
        )}
      </Flex>
    </Flex>
  );
}
