// Chakra imports
import {
  Button,
  Flex,
  Text,
  useColorModeValue,
  FormControl,
  Input,
  Select,
  Switch,
  VStack,
  HStack,
  Center,
  Heading,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Spinner,
  List,
  ListItem,
  ListIcon,
  useToast,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import React, { useState, forwardRef, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { DEGREE_API_URL } from "../../config/apiUrl";
import { COLLEGE_API_URL } from "../../config/apiUrl";
import { BRANCH_API_URL } from "../../config/apiUrl";
import { EDUCATION_API_URL } from "../../config/apiUrl";
import { utils } from "utils";
import makeRequest from "apis/axiosMethods";

const userId = localStorage.getItem("userId");

const GradePlannerForm = () => {
  const toast = useToast();
  const cardBgColor = useColorModeValue("white", "navy.800");

  const [allAcademicItems, setAllAcademicItems] = useState({
    degree: [],
    college: [],
    branch: [],
  });
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    educationId: -1,
    selectedDegree: "",
    selectedCollege: "",
    otherCollegeValue: "",
    selectedBranch: "",
    selectedDegreeMode: "",
    selectedDegreeGradeScale: "",
    totalCredits: 0,
    obtainedCpi: 0.0,
    targetCpi: 0.0,
    startDate: null,
    endDate: null,
    ongoing: false,
  });

  const [collegeSuggestions, setCollegeSuggestions] = useState([]);

  const [isEdit, setIsEdit] = useState(false);

  const customDateInput = ({ value, onClick, onChange }, ref) => (
    <Input
      autoComplete="off"
      background={cardBgColor}
      value={value}
      ref={ref}
      onClick={onClick}
      onChange={onChange}
      width={{ sm: "100%", md: "320px" }}
    />
  );
  customDateInput.displayName = "DateInput";
  const CustomInput = forwardRef(customDateInput);

  const convertNameToId = (allItems, field, name) => {
    if (name === "") return null;
    if (field === "degree") {
      return (
        allItems["degree"].length &&
        allItems["degree"].find((degree) => degree.name === name)?.degreeId
      );
    }
    if (field === "college") {
      return (
        allItems["college"].length &&
        allItems["college"].find((college) => college.name === name)?.collegeId
      );
    }
    if (field === "branch") {
      return (
        allItems["branch"].length &&
        allItems["branch"].find((branch) => branch.name === name)?.branchId
      );
    }
  };

  const convertIdToName = (allItems, field, id) => {
    if (id === "") return null;
    if (field === "degree") {
      return (
        allItems["degree"].length &&
        allItems["degree"].find((degree) => degree.degreeId === id)?.name
      );
    }
    if (field === "college") {
      return (
        allItems["college"].length &&
        allItems["college"].find((college) => college.collegeId === id)?.name
      );
    }
    if (field === "branch") {
      return (
        allItems["branch"].length &&
        allItems["branch"].find((branch) => branch.branchId === id)?.name
      );
    }
  };

  useEffect(() => {
    let allAcadItems = { degree: [], college: [], branch: [] };
    const fetchAllData = async () => {
      setIsLoading(true);
      try {
        // Perform all fetches in parallel
        const results = await Promise.all([
          makeRequest("get", DEGREE_API_URL),
          makeRequest("get", COLLEGE_API_URL),
          makeRequest("get", BRANCH_API_URL),
        ]);
        allAcadItems = {
          degree: results[0].data,
          college: results[1].data,
          branch: results[2].data,
        };
        const url = `${EDUCATION_API_URL}/user/${userId}`;
        const response = await makeRequest("get", url);
        if (response.data) {
          const educationId = response.data.educationId || "";
          const collegeId = response.data.collegeId || "";
          const degreeId = response.data.degreeId || "";
          const branchId = response.data.branchId || "";
          const degreeMode = response.data.degreeMode || "";
          const startDate = new Date(response.data.startDate) || null;
          const endDate = new Date(response.data.endDate) || null;
          const ongoing = response.data.ongoing || false;
          const degreeGradeScale = response.data.degreeGradeScale || "";
          const totalCredits = response.data.totalCredits || 0;
          const obtainedCpi = response.data.obtainedCpi || 0.0;
          const targetCpi = response.data.targetCpi || 0.0;
          const degreeName = convertIdToName(allAcadItems, "degree", degreeId);
          const collegeName = convertIdToName(
            allAcadItems,
            "college",
            collegeId
          );
          const branchName = convertIdToName(allAcadItems, "branch", branchId);

          const newFormData = {
            educationId,
            selectedDegree: degreeName,
            selectedCollege: collegeName,
            selectedBranch: branchName,
            otherCollegeValue: "",
            selectedDegreeMode: degreeMode,
            selectedDegreeGradeScale: degreeGradeScale,
            startDate,
            endDate,
            totalCredits,
            obtainedCpi,
            targetCpi,
            ongoing,
          };
          setFormData(newFormData);
          setIsEdit(true);
        }
        setAllAcademicItems(allAcadItems);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAllData();
  }, []);

  const handleGradePlanningCancel = () => {
    // Handle cancel logic here
    // console.log("Grade planning canceled!");
  };

  const {
    isOpen: isOpenGradePlanning,
    onOpen: onOpenGradePlanning,
    onClose: onCloseGradePlanning,
  } = useDisclosure();
  const cancelResetGradePlanningRef = React.useRef();

  const isDateField = (field) => field === "startDate" || field === "endDate";

  const handleChangeInDetails = (field, value) => {
    if (isDateField(field)) {
      value = utils.convertDateToLocalTimeZone(value);
    }
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleInputCollegeChange = (event) => {
    const value = event.target.value;

    setFormData({
      ...formData,
      selectedCollege: value.name,
    });
    if (!value) {
      setCollegeSuggestions([]);
    } else {
      const filteredSuggestions = allAcademicItems["college"].filter(
        (college) => college.name.toLowerCase().startsWith(value.toLowerCase())
      );
      setCollegeSuggestions(filteredSuggestions);
    }
  };

  const handleCollegeSuggestionClick = (college) => {
    setFormData({
      ...formData,
      selectedCollege: college.name,
    });
    setCollegeSuggestions([]); // Clear suggestions
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const educationId = formData.educationId;

    const totalCredits = Number(formData.totalCredits);
    const obtainedCpi = Number(formData.obtainedCpi);
    const targetCpi = Number(formData.targetCpi);
    let error_msg = "";
    if (!utils.isInteger(totalCredits))
      error_msg += "totalCredits is not an integer.\n";
    if (!utils.isIntegerOrFloat(obtainedCpi))
      error_msg += "obtainedCpi is not a number.\n";
    if (!utils.isIntegerOrFloat(targetCpi))
      error_msg += "targetCpi is not a number.\n";

    // console.log("formData", formData);

    if (error_msg !== "") {
      toast({
        title: "Error in education details.",
        description: error_msg,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      error_msg = "";
      return;
    }

    const body = {
      educationId,
      userId,
      collegeId: convertNameToId(
        allAcademicItems,
        "college",
        formData.selectedCollege
      ),
      degreeId: convertNameToId(
        allAcademicItems,
        "degree",
        formData.selectedDegree
      ),
      branchId: convertNameToId(
        allAcademicItems,
        "branch",
        formData.selectedBranch
      ),
      degreeMode: formData.selectedDegreeMode,
      degreeGradeScale: formData.selectedDegreeGradeScale,
      startDate: formData.startDate,
      endDate: formData.endDate,
      totalCredits,
      obtainedCpi,
      targetCpi,
      ongoing: formData.ongoing,
    };

    try {
      // console.log("🚀 ~ handleSubmit ~ isEdit:", isEdit);
      let operation = "";
      let capturedError = "";
      if (isEdit) {
        try {
          const response = await makeRequest(
            "put",
            `${EDUCATION_API_URL}/${educationId}`,
            body
          );
          if ([200, 201].includes(response.status)) {
            // console.log("Request successful", response.data);
          } else {
            capturedError = utils.extractHttpError(response);
            console.error(
              "Request was not successful with the status:",
              response.status
            );
          }
          operation = "updated";
        } catch (error) {
          capturedError = utils.extractHttpError(error);
        }
      } else {
        try {
          const response = await makeRequest(
            "post",
            `${EDUCATION_API_URL}`,
            body
          );
          if ([200, 201].includes(response.status)) {
            // console.log("Request successful", response.data);
          } else {
            capturedError = utils.extractHttpError(response);
            console.error(
              "Request was not successful with the status:",
              response.status
            );
          }
          operation = "saved";
        } catch (error) {
          capturedError = utils.extractHttpError(error);
        }
      }
      if (capturedError === "") {
        toast({
          title: `Details ${operation}.`,
          description: `We've ${operation} your education details successfully.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        // console.log("Form data submitted successfully");
      } else {
        toast({
          title: "Something went wrong.",
          description: `Got error: ${JSON.stringify(
            capturedError
          )} while saving your education details.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleDelete = async () => {
    const educationId = formData.educationId || "";
    try {
      await makeRequest("delete", `${EDUCATION_API_URL}/${educationId}`);
      // console.log("User data deleted successfully");
    } catch (error) {
      console.error("Error deleting user data:", error);
    }
  };

  return (
    <Flex
      direction="column"
      maxW={{ sm: "320px", md: "100%" }}
      w="100%"
      p={5}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
      borderRadius={"20px"}
    >
      <form onSubmit={handleSubmit}>
        <VStack spacing={4} align="stretch">
          <Center>
            <Heading as="h2" size="lg" mb={2}>
              {"Plan your grades"}
            </Heading>
          </Center>
          {isLoading ? (
            <Flex justify="center" align="center" height="60vh">
              <Spinner size="xl" />
            </Flex>
          ) : (
            <>
              <Flex align={"center"}>
                <Text align={"left"} width={"180px"}>
                  {"Select your degree"}
                </Text>
                <FormControl>
                  <Select
                    value={formData.selectedDegree}
                    onChange={(e) =>
                      handleChangeInDetails("selectedDegree", e.target.value)
                    }
                    width={{ sm: "100%", md: "320px" }}
                  >
                    <option value="">{"Select Degree"}</option>
                    {allAcademicItems["degree"]?.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>
              <Flex align={"center"}>
                <Text align={"left"} width={"180px"}>
                  {"Select your college"}
                </Text>
                <FormControl>
                  <Input
                    placeholder="Type to search for a college..."
                    value={formData.selectedCollege}
                    onChange={handleInputCollegeChange}
                    width={{ sm: "100%", md: "320px" }}
                  />
                  {collegeSuggestions.length > 0 && (
                    <List
                      spacing={2}
                      mt={2}
                      maxH="200px"
                      overflowY="auto"
                      boxShadow="md"
                      p={2}
                      bg="white"
                    >
                      {collegeSuggestions.map((college, index) => (
                        <ListItem
                          key={index}
                          cursor="pointer"
                          _hover={{ bg: "blue.100" }}
                          onClick={() => handleCollegeSuggestionClick(college)}
                        >
                          <ListIcon as={CheckCircleIcon} color="green.500" />
                          {college.name}
                        </ListItem>
                      ))}
                    </List>
                  )}
                </FormControl>
              </Flex>

              {/* {formData.selectedCollege === "others" && (
                <Flex align={"center"}>
                  <Text align={"left"} width={"180px"}>
                    {"Enter college name"}
                  </Text>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="college name goes here"
                      value={formData.otherCollegeValue}
                      onChange={(e) =>
                        handleChangeInDetails(
                          "otherCollegeValue",
                          e.target.value
                        )
                      }
                      width={"400px"}
                    />
                  </FormControl>
                </Flex>
              )} */}

              <Flex align={"center"}>
                <Text align={"left"} width={"180px"}>
                  {"Select your branch"}
                </Text>
                <FormControl>
                  <Select
                    value={formData.selectedBranch}
                    onChange={(e) =>
                      handleChangeInDetails("selectedBranch", e.target.value)
                    }
                    width={{ sm: "100%", md: "320px" }}
                  >
                    <option value="">{"Select Branch"}</option>
                    {allAcademicItems["branch"]?.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>

              <Flex align={"center"}>
                <Text align={"left"} width={"180px"}>
                  {"Select degree mode"}
                </Text>
                <FormControl>
                  <Select
                    value={formData.selectedDegreeMode}
                    onChange={(e) =>
                      handleChangeInDetails(
                        "selectedDegreeMode",
                        e.target.value
                      )
                    }
                    width={{ sm: "100%", md: "320px" }}
                  >
                    <option value="">{"Select Degree Mode"}</option>
                    <option value="FULL_TIME">{"Full Time"}</option>
                    <option value="PART_TIME">{"Part Time"}</option>
                    <option value="DISTANCE">{"Distance"}</option>
                  </Select>
                </FormControl>
              </Flex>

              <Flex align={"center"}>
                <Text align={"left"} width={"180px"}>
                  {"Select grade scale"}
                </Text>
                <FormControl>
                  <Select
                    value={formData.selectedDegreeGradeScale}
                    onChange={(e) =>
                      handleChangeInDetails(
                        "selectedDegreeGradeScale",
                        e.target.value
                      )
                    }
                    width={{ sm: "100%", md: "320px" }}
                  >
                    <option value="">{"Select Grade Scale"}</option>
                    <option value="FOUR">{"4"}</option>
                    <option value="TEN">{"10"}</option>
                    <option value="HUNDRED">{"100"}</option>
                  </Select>
                </FormControl>
              </Flex>
              <Flex align={"center"}>
                <Text align={"left"}>{"Is\u00A0Degree\u00A0Ongoing"}</Text>
                <FormControl ml={"6"}>
                  <Switch
                    isChecked={formData.ongoing}
                    onChange={() =>
                      handleChangeInDetails("ongoing", !formData.ongoing)
                    }
                  />
                </FormControl>
              </Flex>
              <Flex align={"center"}>
                <Text align={"left"} width={"180px"}>
                  {"Degree start date"}
                </Text>
                <FormControl textAlign="left">
                  <DatePicker
                    selected={formData.startDate}
                    onChange={(date) =>
                      handleChangeInDetails("startDate", date)
                    }
                    dateFormat="dd/MM/yyyy"
                    isClearable={true}
                    showPopperArrow={true}
                    dropdownMode="select"
                    showMonthDropdown
                    showYearDropdown
                    maxDate={formData.endDate}
                    customInput={<CustomInput />}
                  />
                </FormControl>
              </Flex>
              {!formData.ongoing && (
                <Flex align={"center"}>
                  <Text align={"left"} width={"180px"}>
                    {"Degree end date"}
                  </Text>
                  <FormControl textAlign="left">
                    <DatePicker
                      selected={!formData.ongoing ? formData.endDate : null}
                      onChange={(date) =>
                        handleChangeInDetails("endDate", date)
                      }
                      dateFormat="dd/MM/yyyy"
                      isClearable={true}
                      showPopperArrow={true}
                      dropdownMode="select"
                      showMonthDropdown
                      showYearDropdown
                      minDate={formData.startDate}
                      customInput={<CustomInput />}
                    />
                  </FormControl>
                </Flex>
              )}
              <Flex align={"center"}>
                <Text align={"left"} width={"180px"}>
                  {"Total Credits"}
                </Text>
                <FormControl>
                  <Input
                    type="text"
                    placeholder="Enter a floating-point number"
                    value={formData.totalCredits}
                    onChange={(e) =>
                      handleChangeInDetails("totalCredits", e.target.value)
                    }
                    width={{ sm: "100%", md: "320px" }}
                  />
                </FormControl>
              </Flex>

              <Flex align={"center"}>
                <Text align={"left"} width={"180px"}>
                  {"Obtained GPA"}
                </Text>
                <FormControl>
                  <Input
                    type="text"
                    placeholder="Enter a floating-point number"
                    value={formData.ongoing ? 0 : formData.obtainedCpi}
                    onChange={(e) =>
                      handleChangeInDetails("obtainedCpi", e.target.value)
                    }
                    width={{ sm: "100%", md: "320px" }}
                    disabled={formData.ongoing}
                  />
                </FormControl>
              </Flex>

              <Flex align={"center"}>
                <Text align={"left"} width={"180px"}>
                  {"Degree Target GPA"}
                </Text>
                <FormControl>
                  <Input
                    type="text"
                    placeholder="Enter a floating-point number"
                    value={formData.ongoing ? formData.targetCpi : 0}
                    onChange={(e) =>
                      handleChangeInDetails("targetCpi", e.target.value)
                    }
                    width={{ sm: "100%", md: "320px" }}
                    disabled={!formData.ongoing}
                  />
                </FormControl>
              </Flex>

              <Flex minWidth="max-content" alignItems="center" gap="2" pt="5">
                <HStack spacing={4}>
                  <Button colorScheme="teal" type="submit">
                    {"Save"}
                  </Button>
                  <Button colorScheme="gray" onClick={onOpenGradePlanning}>
                    {"Reset"}
                  </Button>
                  <AlertDialog
                    isOpen={isOpenGradePlanning}
                    leastDestructiveRef={cancelResetGradePlanningRef}
                    onClose={onCloseGradePlanning}
                  >
                    <AlertDialogOverlay>
                      <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                          {"Reset Grade Planning"}
                        </AlertDialogHeader>

                        <AlertDialogBody>
                          {"You will lose all the unsaved data on this page."}
                        </AlertDialogBody>

                        <AlertDialogFooter>
                          <Button
                            ref={cancelResetGradePlanningRef}
                            onClick={onCloseGradePlanning}
                          >
                            {"Cancel"}
                          </Button>
                          <Button
                            colorScheme="red"
                            onClick={handleGradePlanningCancel}
                            ml={3}
                          >
                            {"Reset"}
                          </Button>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialogOverlay>
                  </AlertDialog>
                </HStack>
              </Flex>
            </>
          )}
        </VStack>
      </form>
    </Flex>
  );
};

export default GradePlannerForm;
