// Chakra imports
import {
  Avatar,
  Flex,
  Text,
  Select,
  Button,
  Icon,
  Link,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useColorModeValue,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { USER_API_URL } from "../../config/apiUrl";
import makeRequest from "apis/axiosMethods";
import { PersonIcon } from "components/Icons/Icons";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { utils } from "utils";

const userId = localStorage.getItem("userId");

function Profile() {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const iconColor = useColorModeValue("blue.500", "white");
  const bgProfile = useColorModeValue("hsla(0,0%,100%,0.9)", "navy.800");
  const borderProfileColor = useColorModeValue("white", "transparent");
  const emailColor = useColorModeValue("gray.400", "gray.300");

  const [isLoading, setIsLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [profileDetails, setProfileDetails] = useState({});

  const toast = useToast();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const handleSave = async () => {
    // console.log(profileDetails);
    try {
      let capturedError = "";
      try {
        const response = await makeRequest(
          "put",
          `${USER_API_URL}/${profileDetails.userId}`,
          profileDetails
        );
        if ([200].includes(response.status)) {
          // console.log("Request successful", response.data);
        } else {
          capturedError = utils.extractHttpError(response);
          console.error(
            "Request was not successful with the status:",
            response.status
          );
        }
      } catch (error) {
        capturedError = utils.extractHttpError(error);
      }
      if (capturedError === "") {
        setEditMode(false);
        toast({
          title: "Profile updated.",
          description: "We've updated your profile details.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Something went wrong.",
          description: `Got error: ${JSON.stringify(
            capturedError
          )} while saving your profile details.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  useEffect(() => {
    const fetchProfileDetails = async () => {
      let capturedError = "";
      try {
        setIsLoading(true);
        const profile_url = `${USER_API_URL}/${userId}`;
        let response = await makeRequest("get", profile_url);
        if (response.data) {
          setProfileDetails(response.data);
        } else {
          capturedError = utils.extractHttpError(response);
        }
      } catch (error) {
        capturedError = utils.extractHttpError(error);
      } finally {
        setIsLoading(false);
      }

      if (capturedError !== "") {
        toast({
          title: "Error fetching data",
          description: JSON.stringify(capturedError.data),
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };
    fetchProfileDetails();
  }, []);

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px", lg: "100px" }}>
      <Flex
        direction={{ sm: "column", md: "row" }}
        mb="24px"
        maxH="1500px"
        width={"60%"}
        justifyContent={{ sm: "center", md: "space-between" }}
        align="center"
        backdropFilter="blur(21px)"
        boxShadow="0px 2px 5.5px rgba(0, 0, 0, 0.02)"
        border="1.5px solid"
        borderColor={borderProfileColor}
        bg={bgProfile}
        p="24px"
        borderRadius="20px"
      >
        {isLoading ? (
          <Flex justify="center" align="center" height="60vh">
            <Spinner size="xl" />
          </Flex>
        ) : (
          <Flex
            align="center"
            mb={{ sm: "10px", md: "0px" }}
            direction={{ sm: "column", md: "column" }}
            w={{ sm: "100%" }}
            textAlign={{ sm: "center", md: "start" }}
          >
            {editMode ? (
              <>
                <FormControl>
                  <FormLabel>{"About you"}</FormLabel>
                  <Textarea
                    name="aboutYou"
                    value={profileDetails.aboutYou}
                    onChange={handleInputChange}
                    maxLength={300}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>{"Gender"}</FormLabel>
                  <Select
                    name="gender"
                    value={profileDetails.gender}
                    onChange={handleInputChange}
                  >
                    <option value="NA">{"Select from the dropdown"}</option>
                    <option value="MALE">{"Male"}</option>
                    <option value="FEMALE">{"Female"}</option>
                    <option value="NA">{"Don't want to say"}</option>
                  </Select>
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>{"Date of Birth"}</FormLabel>
                  <Input
                    name="dob"
                    type="date"
                    value={profileDetails.dob}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>{"Website"}</FormLabel>
                  <Input
                    name="website"
                    value={profileDetails.website}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>{"Facebook URL"}</FormLabel>
                  <Input
                    name="facebookUrl"
                    value={profileDetails.facebookUrl}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>{"Instagram URL"}</FormLabel>
                  <Input
                    name="instagramUrl"
                    value={profileDetails.instagramUrl}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>{"Twitter URL"}</FormLabel>
                  <Input
                    name="twitterUrl"
                    value={profileDetails.twitterUrl}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>{"LinkedIn URL"}</FormLabel>
                  <Input
                    name="linkedinUrl"
                    value={profileDetails.linkedinUrl}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <Flex
                  direction={{ sm: "column", md: "row", lg: "row" }}
                  justifyContent="right"
                  mb={{ sm: "10px", md: "0px" }}
                  ml="24px"
                  mr="20px"
                  w={{ sm: "100%" }}
                  textAlign={{ sm: "center", md: "start" }}
                >
                  <Button mt={4} colorScheme="blue" onClick={handleSave}>
                    {"Save"}
                  </Button>
                  <Button mt={4} ml={3} onClick={handleCancel}>
                    {"Cancel"}
                  </Button>
                </Flex>
              </>
            ) : (
              <>
                <Flex
                  direction={{ sm: "column", md: "row", lg: "row" }}
                  justifyContent="center"
                  align="center"
                  mb={{ sm: "10px", md: "0px" }}
                  ml="24px"
                  w={{ sm: "100%" }}
                  textAlign={{ sm: "center", md: "start" }}
                >
                  <Avatar
                    me={{ md: "0px" }}
                    ml={{ md: "100px" }}
                    src={profileDetails.imageUrl || PersonIcon}
                    w="300px"
                    h="300px"
                    borderRadius="300px"
                  />
                  <Button
                    alignContent={"center"}
                    ml="20px"
                    onClick={() => setEditMode(true)}
                  >
                    {"Edit Profile"}
                  </Button>
                </Flex>

                <Flex direction="column" maxWidth="100%" my={{ sm: "14px" }}>
                  <Text
                    fontSize={{ sm: "lg", lg: "xl" }}
                    color={textColor}
                    fontWeight="bold"
                    ms={{ sm: "8px", md: "0px" }}
                  >
                    {profileDetails.name}
                  </Text>
                  <Text
                    fontSize={{ sm: "sm", md: "md" }}
                    color={emailColor}
                    fontWeight="semibold"
                  >
                    {profileDetails.email}
                  </Text>
                </Flex>
                <Flex
                  direction={{ sm: "column", lg: "row" }}
                  w={{ sm: "100%", md: "90%", lg: "80%" }}
                  justifyContent={{ sm: "center", md: "space-between" }}
                >
                  <Flex direction="column">
                    <Text
                      fontSize="md"
                      color="gray.400"
                      fontWeight="400"
                      mb="30px"
                    >
                      {profileDetails.aboutYou}
                    </Text>
                    <Flex align="center" mb="18px">
                      <Text
                        fontSize="md"
                        color={textColor}
                        fontWeight="bold"
                        me="10px"
                      >
                        Gender:{" "}
                      </Text>
                      <Text fontSize="md" color="gray.400" fontWeight="400">
                        {profileDetails.gender}
                      </Text>
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Text
                        fontSize="md"
                        color={textColor}
                        fontWeight="bold"
                        me="10px"
                      >
                        Date of Birth:{" "}
                      </Text>
                      <Text fontSize="md" color="gray.400" fontWeight="400">
                        {profileDetails.dob}
                      </Text>
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Text
                        fontSize="md"
                        color={textColor}
                        fontWeight="bold"
                        me="10px"
                      >
                        Website:{" "}
                      </Text>
                      <Text fontSize="md" color="gray.400" fontWeight="400">
                        {profileDetails.website}
                      </Text>
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Text
                        fontSize="md"
                        color={textColor}
                        fontWeight="bold"
                        me="10px"
                      >
                        Signed in using:{" "}
                      </Text>
                      <Text fontSize="md" color="gray.400" fontWeight="400">
                        {profileDetails.provider}
                      </Text>
                    </Flex>
                    <Flex align="center" mb="18px">
                      <Text
                        fontSize="md"
                        color={textColor}
                        fontWeight="bold"
                        me="10px"
                      >
                        Social Media:{" "}
                      </Text>
                      <Flex>
                        {profileDetails.facebookUrl && (
                          <Link
                            href={utils.fixUrl(profileDetails.facebookUrl)}
                            color={iconColor}
                            fontSize="lg"
                            me="10px"
                            _hover={{ color: "blue.500" }}
                          >
                            <Icon as={FaFacebook} />
                          </Link>
                        )}
                        {profileDetails.instagramUrl && (
                          <Link
                            href={utils.fixUrl(profileDetails.instagramUrl)}
                            color={iconColor}
                            fontSize="lg"
                            me="10px"
                            _hover={{ color: "blue.500" }}
                          >
                            <Icon as={FaInstagram} />
                          </Link>
                        )}
                        {profileDetails.twitterUrl && (
                          <Link
                            href={utils.fixUrl(profileDetails.twitterUrl)}
                            color={iconColor}
                            fontSize="lg"
                            me="10px"
                            _hover={{ color: "blue.500" }}
                          >
                            <Icon as={FaTwitter} />
                          </Link>
                        )}
                        {profileDetails.linkedinUrl && (
                          <Link
                            href={profileDetails.linkedinUrl}
                            color={iconColor}
                            fontSize="lg"
                            me="10px"
                            _hover={{ color: "blue.500" }}
                          >
                            <Icon as={FaLinkedin} />
                          </Link>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
              </>
            )}
          </Flex>
        )}
        ;
      </Flex>
    </Flex>
  );
}

export default Profile;
