import {
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import IconBox from "components/Icons/IconBox";
import { RocketIcon } from "components/Icons/Icons.js";
import { FaHeart, FaHeartBroken } from "react-icons/fa";
import React from "react";

export default function GradesGrid(props) {
  const { transcriptSemestersData, educationBasicDetails } = props;

  const iconBlue = useColorModeValue("blue.500", "blue.500");
  const iconBoxInside = useColorModeValue("white", "white");
  const textColor = useColorModeValue("gray.700", "white");
  const cardBgColor = useColorModeValue("white", "navy.800");

  if (
    transcriptSemestersData.length > 0 &&
    Object.keys(educationBasicDetails).length > 0
  ) {
    const degreeGradeScale = educationBasicDetails.degreeGradeScale;
    const semesterCount = transcriptSemestersData.length;
    const currentCpi = transcriptSemestersData[semesterCount - 1].obtainedCpi;
    const lastCpi =
      semesterCount <= 1
        ? 0
        : transcriptSemestersData[semesterCount - 2].obtainedCpi;
    const cpiDiff = (currentCpi - lastCpi).toFixed(2);
    const currentSpi = transcriptSemestersData[semesterCount - 1].obtainedSpi;

    const lastSpi =
      semesterCount <= 1
        ? 0
        : transcriptSemestersData[semesterCount - 2].obtainedSpi;
    const spiDiff = (currentSpi - lastSpi).toFixed(2);

    const ascendingOrderSpiSemesters = [...transcriptSemestersData].sort(
      (a, b) => a.obtainedSpi - b.obtainedSpi
    );
    const lowestSpi = ascendingOrderSpiSemesters[0].obtainedSpi;
    const secondLowestSpi =
      semesterCount <= 1
        ? lowestSpi
        : ascendingOrderSpiSemesters[1].obtainedSpi;
    const diffLowestTwo = (lowestSpi - secondLowestSpi).toFixed(2);

    const descendingOrderSpiSemesters = [...transcriptSemestersData].sort(
      (a, b) => b.obtainedSpi - a.obtainedSpi
    );
    const highestSpi = descendingOrderSpiSemesters[0].obtainedSpi;
    const secondHighestSpi =
      semesterCount <= 1
        ? highestSpi
        : descendingOrderSpiSemesters[1].obtainedSpi;
    const diffHighestTwo = (highestSpi - secondHighestSpi).toFixed(2);

    return (
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px" mb="20px">
        <Card minH="125px" bg={cardBgColor} maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction="column">
            <Flex
              flexDirection="row"
              align="center"
              justify="center"
              w="100%"
              mb="25px"
            >
              <Stat me="auto">
                <StatLabel
                  fontSize="xs"
                  color="gray.400"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  {educationBasicDetails.ongoing ? "Current" : "Final"}
                  {" CGPA"}
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                    {currentCpi} {"out of"} {degreeGradeScale}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox borderRadius="50%" h={"45px"} w={"45px"} bg={iconBlue}>
                <RocketIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            <Text color="gray.400" fontSize="sm">
              {cpiDiff > 0 && (
                <Text as="span" color="green.400" fontWeight="bold">
                  {cpiDiff > 0 ? "+" : ""} {cpiDiff}{" "}
                </Text>
              )}
              {cpiDiff < 0 && (
                <Text as="span" color="red.400" fontWeight="bold">
                  {cpiDiff > 0 ? "+" : ""} {cpiDiff}{" "}
                </Text>
              )}
              {"Since last semester"}
            </Text>
          </Flex>
        </Card>
        <Card minH="125px" bg={cardBgColor} maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction="column">
            <Flex
              flexDirection="row"
              align="center"
              justify="center"
              w="100%"
              mb="25px"
            >
              <Stat me="auto">
                <StatLabel
                  fontSize="xs"
                  color="gray.400"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  {"Last Semester GPA"}
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                    {currentSpi} {"out of"} {degreeGradeScale}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox borderRadius="50%" h={"45px"} w={"45px"} bg={iconBlue}>
                <RocketIcon h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            <Text color="gray.400" fontSize="sm">
              {spiDiff > 0 && (
                <Text as="span" color="green.400" fontWeight="bold">
                  {spiDiff > 0 ? "+" : ""} {spiDiff}{" "}
                </Text>
              )}
              {spiDiff < 0 && (
                <Text as="span" color="red.400" fontWeight="bold">
                  {spiDiff > 0 ? "+" : ""} {spiDiff}{" "}
                </Text>
              )}
              {"Since last semester"}
            </Text>
          </Flex>
        </Card>
        <Card minH="125px" bg={cardBgColor} maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction="column">
            <Flex
              flexDirection="row"
              align="center"
              justify="center"
              w="100%"
              mb="25px"
            >
              <Stat me="auto">
                <StatLabel
                  fontSize="xs"
                  color="gray.400"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  {"Highest Semester GPA Obtained"}
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                    {highestSpi} {"out of"} {degreeGradeScale}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox borderRadius="50%" h={"45px"} w={"45px"} bg={iconBlue}>
                <FaHeart h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            <Text color="gray.400" fontSize="sm">
              {diffHighestTwo > 0 && (
                <Text as="span" color="green.400" fontWeight="bold">
                  {diffHighestTwo > 0 ? "+" : ""} {diffHighestTwo}{" "}
                </Text>
              )}
              {diffHighestTwo < 0 && (
                <Text as="span" color="red.400" fontWeight="bold">
                  {diffHighestTwo > 0 ? "+" : ""} {diffHighestTwo}{" "}
                </Text>
              )}
              {"Over second highest semester GPA"}
            </Text>
          </Flex>
        </Card>
        <Card minH="125px" bg={cardBgColor} maxW={{ sm: "320px", md: "100%" }}>
          <Flex direction="column">
            <Flex
              flexDirection="row"
              align="center"
              justify="center"
              w="100%"
              mb="25px"
            >
              <Stat me="auto">
                <StatLabel
                  fontSize="xs"
                  color="gray.400"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  {"Lowest Semester GPA Obtained"}
                </StatLabel>
                <Flex>
                  <StatNumber fontSize="lg" color={textColor} fontWeight="bold">
                    {lowestSpi} {"out of"} {degreeGradeScale}
                  </StatNumber>
                </Flex>
              </Stat>
              <IconBox borderRadius="50%" h={"45px"} w={"45px"} bg={iconBlue}>
                <FaHeartBroken h={"24px"} w={"24px"} color={iconBoxInside} />
              </IconBox>
            </Flex>
            <Text color="gray.400" fontSize="sm">
              {diffLowestTwo > 0 && (
                <Text as="span" color="green.400" fontWeight="bold">
                  {diffLowestTwo > 0 ? "+" : ""} {diffLowestTwo}{" "}
                </Text>
              )}
              {diffLowestTwo < 0 && (
                <Text as="span" color="red.400" fontWeight="bold">
                  {diffLowestTwo > 0 ? "+" : ""} {diffLowestTwo}{" "}
                </Text>
              )}
              {"Over second last semester CGPA"}
            </Text>
          </Flex>
        </Card>
      </SimpleGrid>
    );
  } else return null;
}
