if (process.env.NODE_ENV === "development") {
  module.exports = require("./dev");
} else if (process.env.NODE_ENV === "production") {
  module.exports = require("./prod");
}
if (process.env.REACT_APP_STAGE === "dev") {
  module.exports = require("./dev");
} else if (process.env.REACT_APP_STAGE === "qa") {
  module.exports = require("./qa");
} else if (process.env.REACT_APP_STAGE === "prod") {
  module.exports = require("./prod");
}
