import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import AdminLayout from "layouts/Admin";
import theme from "theme/theme";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import SignInSignUpPage from "views/Pages/SignInSignUpPage";
import OAuth2RedirectHandler from "user/oauth2/OAuth2RedirectHandler";
import NotFound from "common/NotFound";

function App() {
  return (
    <ChakraProvider theme={theme} resetCss={false} position="relative">
      <HashRouter>
        <OAuth2RedirectHandler />
        <Routes>
          <Route path={`/admin/*`} element={<AdminLayout />} />
          <Route
            exact
            path="/"
            element={<Navigate replace to="/admin/dashboard" />}
          />
          <Route
            path="/login/oauth2/redirect"
            element={<OAuth2RedirectHandler />}
          />
          <Route path="/oauth2/redirect" element={<OAuth2RedirectHandler />} />
          <Route path="/login" element={<SignInSignUpPage />}></Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
    </ChakraProvider>
  );
}

export default App;
