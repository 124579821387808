// Chakra imports
import {
  Box,
  Flex,
  Grid,
  Spinner,
  Text,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card.js";
import BarChart from "components/Charts/BarChart";
import React, { useState, useEffect } from "react";
import GradesGrid from "./GradesGrid";
import {
  SEMESTER_API_URL,
  USER_API_URL,
  COURSE_API_URL,
  EDUCATION_API_URL,
  COLLEGE_API_URL,
  DEGREE_API_URL,
  BRANCH_API_URL,
} from "../../config/apiUrl";

import makeRequest from "apis/axiosMethods";
import { utils } from "utils";
import { barChartOptions } from "variables/charts";
import ShowEmptyDataAlert from "views/Pages/ShowEmptyDataAlert";

const userId = localStorage.getItem("userId");

export default function GradeBreakup() {
  const { colorMode } = useColorMode();

  const [isLoading, setIsLoading] = useState(false);
  const [profileDetails, setProfileDetails] = useState({});
  const [transcriptSemestersData, setTranscriptSemestersData] = useState([]);
  const [educationBasicDetails, setEducationBasicDetails] = useState({});

  const toast = useToast();

  useEffect(() => {
    if (!userId) return;
    const fetchBasicDetails = async () => {
      try {
        let capturedError = "";
        const educationBasicDetails = {};
        setIsLoading(true);
        try {
          const userEducationResults = await Promise.all([
            makeRequest("get", `${USER_API_URL}/${userId}`),
            makeRequest("get", `${EDUCATION_API_URL}/user/${userId}`),
            makeRequest("get", `${SEMESTER_API_URL}/user/${userId}`),
          ]);
          const profileDetails = userEducationResults[0].data || {};
          const educationData = userEducationResults[1].data || {};
          const semesterData = userEducationResults[2].data || [];

          educationBasicDetails.degreeGradeScale =
            utils.getDegreeGradeScaleNumeric(educationData.degreeGradeScale);
          educationBasicDetails.degreeMode = educationData.degreeMode;
          educationBasicDetails.startDate = educationData.startDate;
          educationBasicDetails.endDate = educationData.endDate;
          educationBasicDetails.totalCredits = educationData.totalCredits;
          educationBasicDetails.ongoing = educationData.ongoing;

          const collegeId = educationData.collegeId;
          const branchId = educationData.branchId;
          const degreeId = educationData.degreeId;
          const [college, branch, degree] = await Promise.all([
            makeRequest("get", `${COLLEGE_API_URL}/${collegeId}`),
            makeRequest("get", `${BRANCH_API_URL}/${branchId}`),
            makeRequest("get", `${DEGREE_API_URL}/${degreeId}`),
          ]);

          educationBasicDetails.collegeName = college?.data?.name;
          educationBasicDetails.collegeWebsite = college?.data?.website;
          educationBasicDetails.branchName = branch?.data?.name;
          educationBasicDetails.degreeName = degree?.data?.name;

          let courseResults = await Promise.all(
            semesterData.map((semester) =>
              makeRequest(
                "get",
                `${COURSE_API_URL}/semester/${semester.semesterId}`
              )
            )
          );
          courseResults = courseResults
            .filter((result) => result.data?.length > 0)
            .map((result) => result.data);
          let transcriptSemestersData = semesterData.map((semester, index) => ({
            ...semester,
            courses:
              index < courseResults.length
                ? courseResults[index].map((course) => ({
                    code: course.code,
                    name: course.name,
                    totalCredits: course.totalCredits,
                    obtainedGrade: course.obtainedGrade,
                  }))
                : [],
          }));
          transcriptSemestersData = transcriptSemestersData.map(
            (semester, index) => {
              const newSpi = utils.getSpiForSemester(
                semester,
                educationBasicDetails.degreeGradeScale
              );

              const newCpi = utils.getCpiSoFarForSemester(
                transcriptSemestersData.slice(0, index + 1),
                educationBasicDetails.degreeGradeScale
              );

              return {
                ...semester,
                obtainedSpi: isNaN(newSpi) ? semester.obtainedSpi : newSpi,
                obtainedCpi: isNaN(newCpi) ? semester.obtainedCpi : newCpi,
              };
            }
          );
          setProfileDetails(profileDetails);
          setTranscriptSemestersData(transcriptSemestersData);
          setEducationBasicDetails(educationBasicDetails);
        } catch (error) {
          capturedError = utils.extractHttpError(error);
        }

        if (capturedError !== "") {
          toast({
            title: "Error fetching data",
            description: JSON.stringify(capturedError.data),
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchBasicDetails();
  }, []);

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <GradesGrid
        educationBasicDetails={educationBasicDetails}
        transcriptSemestersData={transcriptSemestersData}
      />
      {isLoading ? (
        <Flex justify="center" align="center" height="60vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <>
          {educationBasicDetails && transcriptSemestersData.length > 0 ? (
            <Grid
              templateColumns={{ sm: "1fr", lg: "1fr 1fr" }}
              templateRows={{ lg: "repeat(2, auto)" }}
              gap="20px"
            >
              {transcriptSemestersData.map((semester) => {
                const gradeBarChartOptions = {
                  ...barChartOptions,
                  xaxis: {
                    ...barChartOptions.xaxis,
                    categories: semester.courses.map((course) => course.name),
                  },
                };
                const gradeBarChartData = [
                  {
                    name: "Obtained Grades",
                    data: semester.courses.map(
                      (course) => course.obtainedGrade
                    ),
                  },
                ];

                const creditBarChartOptions = {
                  ...barChartOptions,
                  xaxis: {
                    ...barChartOptions.xaxis,
                    categories: semester.courses.map((course) => course.name),
                  },
                };
                const totalCreditsData = [
                  {
                    name: "Course Credits",
                    data: semester.courses.map((course) => course.totalCredits),
                  },
                ];
                const semesterName =
                  semester.name.charAt(0).toUpperCase() +
                  semester.name.slice(1);
                return (
                  <React.Fragment key={semester.name}>
                    <Card
                      bg={
                        colorMode === "dark"
                          ? "navy.800"
                          : "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                      }
                      p="0px"
                      maxW={{ sm: "320px", md: "100%" }}
                    >
                      <Flex direction="column" mb="40px" p="28px 0px 0px 22px">
                        <Text
                          color="#fff"
                          fontSize="lg"
                          fontWeight="bold"
                          mb="6px"
                        >
                          {"Credits in " + semesterName}
                        </Text>
                      </Flex>
                      <Box minH="300px">
                        {semester.courses.length > 0 ? (
                          <BarChart
                            chartData={totalCreditsData}
                            chartOptions={creditBarChartOptions}
                          />
                        ) : (
                          <Text color="gray.400" ml={"10px"}>
                            {
                              "No data available. Start by adding courses to this semester."
                            }
                          </Text>
                        )}
                      </Box>
                    </Card>
                    <Card
                      bg={
                        colorMode === "dark"
                          ? "navy.800"
                          : "linear-gradient(81.62deg, #313860 2.25%, #151928 79.87%)"
                      }
                      p="0px"
                      maxW={{ sm: "320px", md: "100%" }}
                    >
                      <Flex direction="column" mb="40px" p="28px 0px 0px 22px">
                        <Text
                          color="#fff"
                          fontSize="lg"
                          fontWeight="bold"
                          mb="6px"
                        >
                          {"Obtained Grades in " + semesterName}
                        </Text>
                      </Flex>
                      <Box minH="300px">
                        {semester.courses.length > 0 ? (
                          <BarChart
                            chartData={gradeBarChartData}
                            chartOptions={gradeBarChartOptions}
                          />
                        ) : (
                          <Text color="gray.400" ml={"10px"}>
                            {
                              "No data available. Start by adding courses to this semester."
                            }
                          </Text>
                        )}
                      </Box>
                    </Card>
                  </React.Fragment>
                );
              })}
            </Grid>
          ) : (
            <ShowEmptyDataAlert
              message={"No data found. Start by going to the grade-planner."}
            />
          )}
        </>
      )}
    </Flex>
  );
}
