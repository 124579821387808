// Chakra imports
import {
  Button,
  Flex,
  Grid,
  Text,
  Tooltip,
  useColorModeValue,
  Spacer,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { ACCESS_TOKEN, USER_API_URL } from "../../config/apiUrl";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import makeRequest from "apis/axiosMethods";
import { utils } from "utils";

const userId = localStorage.getItem("userId");

function AccountSettings(props) {
  const textColor = useColorModeValue("gray.600", "white");
  const emailColor = useColorModeValue("gray.600", "gray.300");
  const cardBgColor = useColorModeValue("gray.50", "navy.800");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();
  const toast = useToast();

  const [profileDetails, setProfileDetails] = useState({});

  useEffect(() => {
    if (!userId) return;
    const fetchProfileDetails = async () => {
      const profile_url = `${USER_API_URL}/${userId}`;
      let response = await makeRequest("get", profile_url);
      if (response.data) {
        setProfileDetails(response.data);
      }
    };
    fetchProfileDetails();
  }, []);

  const onDeleteUser = async () => {
    try {
      let capturedError = "";
      try {
        const response = await makeRequest(
          "delete",
          `${USER_API_URL}/${profileDetails.userId}`
        );
        if ([200].includes(response.status)) {
          // console.log("Deletion successful", response.data);
        } else {
          capturedError = utils.extractHttpError(response);
          console.error(
            "Delete account was not successful with the status:",
            response.status
          );
        }
      } catch (error) {
        capturedError = utils.extractHttpError(error);
      }
      if (capturedError === "") {
        localStorage.removeItem("userId");
        localStorage.removeItem(ACCESS_TOKEN);
        toast({
          title: "Account deleted.",
          description:
            "We've deleted your account successfully. Thank you for using GPA Calculator.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        window.location.href = window.location.origin + "#/login";
      } else {
        toast({
          title: "Something went wrong",
          description: `Got error: ${JSON.stringify(
            capturedError
          )} while exporting your data.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error: ", error);
    }
    onClose();
  };

  const handleExportData = async () => {
    toast({
      title: "Exporting your data",
      description:
        "Please wait while we work on exporting your data. Your data will be exported soon.",
      status: "info",
      duration: 3000,
      isClosable: true,
    });
    try {
      let capturedError = "";
      try {
        const response = await makeRequest(
          "get",
          `${USER_API_URL}/export/${profileDetails.userId}`
        );
        if ([200].includes(response.status)) {
          // console.log("Request successful", response.data);
        } else {
          capturedError = utils.extractHttpError(response);
          console.error(
            "Request was not successful with the status:",
            response.status
          );
        }
      } catch (error) {
        capturedError = utils.extractHttpError(error);
      }
      if (capturedError !== "") {
        toast({
          title: "Something went wrong",
          description: `Got error: ${JSON.stringify(
            capturedError
          )} while exporting your data.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error: ", error);
    }
    onClose();
  };

  return (
    <Flex direction="column" pt={{ base: "120px", md: "75px", lg: "100px" }}>
      <Grid templateColumns={{ sm: "1fr", xl: "1fr" }} gap="22px">
        <Card p="16px" my={{ sm: "24px", xl: "0px" }} bg={cardBgColor}>
          <CardBody px="5px">
            <Text fontSize="xl" fontWeight={"bold"} mb={4}>
              {"Name:"}
            </Text>
            <Text fontSize="large" mb={4} mr={10} color={textColor}>
              {profileDetails.name}
            </Text>
            <Text fontSize="xl" fontWeight={"bold"} mb={4}>
              {"Registered Email"}
            </Text>
            <Text fontSize="large" mb={4} mr={10} color={emailColor}>
              {profileDetails.email}
            </Text>
          </CardBody>
          <Spacer p="20px" />

          <Flex
            direction={"column"}
            justifyContent="left"
            mb={{ sm: "10px", md: "0px" }}
            ml="24px"
            mr="20px"
            w={{ sm: "100%" }}
            pb={"10px"}
            textAlign={{ sm: "center", md: "start" }}
          >
            <Tooltip
              placement="bottom"
              isDisabled={false}
              label="Exporting the data feature will be available soon"
              aria-label="Exporting the data feature will be available soon"
            >
              <Button
                colorScheme="green"
                w="150px"
                px="40px"
                me="40px"
                isDisabled={true}
                onClick={handleExportData}
              >
                {"Export Data"}
              </Button>
            </Tooltip>

            <Button
              colorScheme="red"
              w="150px"
              px="40px"
              me="40px"
              mt="20px"
              onClick={onOpen}
            >
              {"Delete Account"}
            </Button>
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={onClose}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    {"Delete Account"}
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    {"Are you sure? You can't undo this action afterwards."}
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      {"Cancel"}
                    </Button>
                    <Button colorScheme="red" onClick={onDeleteUser} ml={3}>
                      {"Delete"}
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </Flex>
        </Card>
      </Grid>
    </Flex>
  );
}

export default AccountSettings;
