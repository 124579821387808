import React from "react";
import { Box, Button, Flex, Heading, useColorMode } from "@chakra-ui/react";
import { GOOGLE_AUTH_URL } from "../../config/apiUrl";
import { FcGoogle } from "react-icons/fc";
import OAuth2RedirectHandler from "user/oauth2/OAuth2RedirectHandler";

const SignInSignUpPage = () => {
  const { colorMode } = useColorMode();

  return (
    <>
      <OAuth2RedirectHandler />
      <Flex
        minHeight="100vh"
        width="full"
        align="center"
        justifyContent="center"
        bgGradient={
          colorMode === "light"
            ? "linear(to-b, gray.100, blue.100)"
            : "linear(to-b, gray.800, blue.900)"
        }
        bgSize="cover"
        bgImage="url('https://images.unsplash.com/photo-1546953304-5d96f43c2e94')"
      >
        <Box
          borderWidth={1}
          px={8}
          py={12}
          width="400px"
          textAlign="center"
          rounded="xl"
          bg={colorMode === "dark" ? "navy.800" : "white"}
          boxShadow="xl"
          fontSize="xl"
          p={8}
        >
          <Heading as="h2" size="xl" mb={6}>
            {"Welcome to GPA Calculator"}
          </Heading>
          <Button
            leftIcon={<FcGoogle />}
            colorScheme="teal"
            variant="solid"
            size="lg"
            width="full"
            mb={3}
            maxW="md"
            _hover={{ bg: "blue.600" }}
            onClick={() => {
              window.location.href = GOOGLE_AUTH_URL;
            }}
          >
            {"Sign in with Google"}
          </Button>
        </Box>
      </Flex>
    </>
  );
};

export default SignInSignUpPage;
