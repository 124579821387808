import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Heading,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Switch,
  Card,
  Text,
  Spacer,
  Flex,
  ButtonGroup,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  IconButton,
  Tooltip,
  HStack,
  Spinner,
} from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./dashboard.css";
import { SEMESTER_API_URL } from "../../config/apiUrl";
import { COURSE_API_URL } from "../../config/apiUrl";
import { utils } from "utils";
import makeRequest from "apis/axiosMethods";

const userId = localStorage.getItem("userId");
const templateSemester = {
  semesterId: -1,
  userId,
  number: -1,
  name: "",
  semesterType: "",
  ongoing: false,
  startDate: null,
  endDate: null,
  totalCredits: 0,
  obtainedSpi: 0.0,
  targetSpi: 0.0,
  isEdit: false,
};

const templateCourse = {
  courseId: -1,
  userId,
  semesterId: -1,
  code: "",
  name: "",
  ongoing: false,
  startDate: null,
  endDate: null,
  totalCredits: 0,
  obtainedGrade: 0,
  targetGrade: 0,
  isEdit: false,
};

const SemesterForm = () => {
  const toast = useToast();
  const cancelDeleteSemesterRef = React.useRef();
  const {
    isOpen: isOpenDeleteAlert,
    onOpen: onOpenDeleteAlert,
    onClose: onCloseDeleteAlert,
  } = useDisclosure();

  const [semesters, setSemesters] = useState([]);
  const [semesterTypes, setSemesterTypes] = useState([]);
  const [selectedSemesterId, setSelectedSemesterId] = useState(null);
  const [isOpenModalCourses, setIsOpenModalCourses] = useState(false);
  const [courses, setCourses] = useState([templateCourse]);
  const [isLoading, setIsLoading] = useState(false);
  const [semesterForCoursesEdit, setSemesterForCoursesEdit] = useState(null);

  useEffect(() => {
    // let allAcadItems = { degree: [], college: [], branch: [] };
    const fetchAllData = async () => {
      setIsLoading(true);
      try {
        // Perform all fetches in parallel
        const semester_type_url = `${SEMESTER_API_URL}/type`;
        let response = await makeRequest("get", semester_type_url);
        if (response.data) {
          setSemesterTypes(response.data);
        }
        const url = `${SEMESTER_API_URL}/user/${userId}`;
        response = await makeRequest("get", url);
        if (response.data) {
          const semesters = response.data.map((semester) => ({
            ...semester,
            startDate: new Date(semester.startDate),
            endDate: new Date(semester.endDate),
            isEdit: true,
          }));
          setSemesters(semesters);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchAllData();
  }, []);

  const isDateField = (field) => field === "startDate" || field === "endDate";

  const handleChangeInSemester = (semesterIndex, field, value) => {
    if (isDateField(field)) {
      value = utils.convertDateToLocalTimeZone(value);
    }
    const newSemesters = semesters.map((semester, index) =>
      semesterIndex === index ? { ...semester, [field]: value } : semester
    );
    setSemesters(newSemesters);
  };

  const addSemesterAndToggleCurrentSemesterCard = () => {
    // console.log("Current length is: ", semesters.length);
    const newSemesters = [...semesters];
    newSemesters.push({ ...templateSemester });
    setSemesters(newSemesters);
  };

  const handleSemesterSave = async (index) => {
    const semester = semesters[index];
    // semester.number = index + 1;
    const error = utils.isValidSemester(semester);
    if (error !== "") {
      toast({
        title: `Incorrect details for semester.`,
        description: `You have following errors:\n${error}.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const isEdit = semester.isEdit;
    const semesterId = semester.semesterId;
    try {
      let operation = "";
      let capturedError = "";
      if (isEdit) {
        try {
          const response = await makeRequest(
            "put",
            `${SEMESTER_API_URL}/${semesterId}`,
            semester
          );
          if ([200, 201].includes(response.status)) {
            // console.log("Request successful", response.data);
          } else {
            capturedError = utils.extractHttpError(response);
            console.error(
              "Request was not successful with the status:",
              response.status
            );
          }
          operation = "updated";
        } catch (error) {
          capturedError = utils.extractHttpError(error);
        }
      } else {
        try {
          const response = await makeRequest(
            "post",
            `${SEMESTER_API_URL}`,
            semester
          );
          if ([200, 201].includes(response.status)) {
            // console.log("Request successful", response.data);
          } else {
            capturedError = utils.extractHttpError(response);
            console.error(
              "Request was not successful with the status:",
              response.status
            );
          }
          operation = "saved";
        } catch (error) {
          capturedError = utils.extractHttpError(error);
        }
      }
      if (capturedError === "") {
        toast({
          title: `Details ${operation}.`,
          description: `${operation} semester details successfully.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Something went wrong.",
          description: `Got error: ${JSON.stringify(
            capturedError
          )} while saving your semester details.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleSemesterDeleteClick = (index) => {
    setSelectedSemesterId(index);
    onOpenDeleteAlert();
  };

  const deleteSemester = async () => {
    const semester = semesters[selectedSemesterId];
    // console.log(JSON.stringify(semester));
    onCloseDeleteAlert();
    let newSemesters = semesters.filter(
      (_, index) => index !== selectedSemesterId
    );
    if (newSemesters.length === 0) {
      newSemesters = [];
    }
    if (semester.semesterId === -1) {
      setSemesters(newSemesters);
      setSelectedSemesterId(null);
      return;
    }
    try {
      setIsLoading(true);
      await makeRequest("delete", `${SEMESTER_API_URL}/${semester.semesterId}`);
      // console.log(
      //   `Semester with ID: ${semester.semesterId} along with its courses deleted successfully`
      // );
      toast({
        title: `Semester deleted.`,
        description: `Semester deleted successfully.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setSemesters(newSemesters);
      setSelectedSemesterId(null);
    } catch (error) {
      console.error("Error deleting semester: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const resetCourses = () => {
    setCourses([templateCourse]);
  };

  const toggleCoursesModal = () => {
    setIsOpenModalCourses(!isOpenModalCourses);
  };

  const handleOpenSemesterForCoursesEdit = async (semester) => {
    setIsLoading(true);
    try {
      const course_api_url = `${COURSE_API_URL}/semester/${semester.semesterId}`;
      let response = await makeRequest("get", course_api_url);
      if (response.data) {
        const courses = response.data.map((course) => ({
          ...course,
          startDate: new Date(course.startDate),
          endDate: new Date(course.endDate),
          isEdit: true,
        }));
        setCourses(courses);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setSemesterForCoursesEdit(semester);
      setIsLoading(false);
    }
  };

  const handleChangeInCourse = (courseIndex, field, value) => {
    if (isDateField(field)) {
      value = utils.convertDateToLocalTimeZone(value);
    }
    const newCourses = courses.map((course, index) =>
      courseIndex === index ? { ...course, [field]: value } : course
    );
    setCourses(newCourses);
  };

  const addCourseRow = () => {
    const newCourses = [...courses];
    newCourses.push({ ...templateCourse });
    setCourses(newCourses);
  };

  const deleteCourseRow = async (index) => {
    const course = courses[index];
    const newCourses = [
      ...courses.slice(0, index),
      ...courses.slice(index + 1),
    ];
    // console.log(JSON.stringify(newCourses));

    if (course.courseId === -1) {
      setCourses(newCourses);
      return;
    }
    try {
      let capturedError = "";
      try {
        const response = await makeRequest(
          "delete",
          `${COURSE_API_URL}/${course.courseId}`
        );
        if ([200, 201].includes(response.status)) {
          // console.log(`Course with index: ${index} deleted successfully`);
          setCourses(newCourses);
        } else {
          capturedError = utils.extractHttpError(response);
          console.error(
            "Request was not successful with the status:",
            response.status
          );
        }
      } catch (error) {
        capturedError = utils.extractHttpError(error);
      }
      if (capturedError === "") {
        toast({
          title: `Course deleted.`,
          description: `Course deleted successfully.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Something went wrong.",
          description: `Got error: ${JSON.stringify(
            capturedError
          )} while deleting the course.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const handleCoursesSave = async () => {
    // console.log(JSON.stringify(semesterForCoursesEdit));
    const newCourses = courses.map((course) => ({
      ...course,
      semesterId: semesterForCoursesEdit.semesterId,
      startDate: semesterForCoursesEdit.startDate,
      endDate: semesterForCoursesEdit.endDate,
      ongoing: semesterForCoursesEdit.ongoing,
    }));
    let errors = "";
    newCourses.forEach((course) => {
      errors += utils.isValidCourse(course);
    });
    if (errors !== "") {
      toast({
        title: "Error in editing courses.",
        description: "You have errors in the page: " + errors,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // console.log(JSON.stringify(newCourses));
    try {
      let capturedError = "";
      try {
        const response = await makeRequest(
          "put",
          `${COURSE_API_URL}/bulk`,
          newCourses
        );
        if ([200, 201].includes(response.status)) {
          // console.log("Request successful", response.data);
          setIsOpenModalCourses(false);
          resetCourses();
        } else {
          capturedError = utils.extractHttpError(response);
          console.error(
            "Request was not successful with the status:",
            response.status
          );
        }
      } catch (error) {
        capturedError = utils.extractHttpError(error);
      }
      if (capturedError === "") {
        toast({
          title: `Details saved.`,
          description: `Saved courses successfully.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Something went wrong.",
          description: `Got error: ${JSON.stringify(
            capturedError
          )} while saving your semester details.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const AddSemesterEmptyCard = () => (
    <Card
      key="-1"
      borderRadius="lg"
      p={4}
      position="relative"
      textAlign="center"
      minHeight="300px"
      minWidth={"320px"}
      bg={"cardBgColor"}
    >
      <Button
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        onClick={() => addSemesterAndToggleCurrentSemesterCard()}
      >
        <AddIcon />{" "}
        <Text ml={"2"} mt={"1"}>
          {"Add Semester"}
        </Text>
      </Button>
    </Card>
  );

  return (
    <Box>
      <Heading as="h3" size="lg" mb={4}>
        {"Edit Semesters"}
      </Heading>
      {isLoading ? (
        <Flex justify="center" align="center" height="60vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
          gap={4}
        >
          {semesters.map((semester, semesterIndex) => {
            const ongoing = semesters[semesterIndex].ongoing;
            return (
              <Card
                key={semesterIndex}
                borderRadius="lg"
                p={4}
                position="relative"
                textAlign="center"
                minHeight="300px"
                minWidth={"320px"}
                bg={"cardBgColor"}
              >
                <form>
                  <Flex align={"center"} mb={"3"}>
                    <Text align={"left"}>{"Sem\u00A0No"}</Text>
                    <FormControl textAlign="left" ml={"3"}>
                      <Input
                        type="text"
                        width={"100px"}
                        value={semester.number !== -1 ? semester.number : ""}
                        onChange={(e) =>
                          handleChangeInSemester(
                            semesterIndex,
                            "number",
                            e.target.value
                          )
                        }
                      />
                    </FormControl>
                    <FormControl display="flex" alignItems="center">
                      <FormLabel htmlFor="ongoing" ml="10" mb="0">
                        {"Is\u00A0Ongoing"}
                      </FormLabel>
                      <Switch
                        id="ongoing"
                        isChecked={ongoing}
                        onChange={() =>
                          handleChangeInSemester(
                            semesterIndex,
                            "ongoing",
                            !ongoing
                          )
                        }
                        size="md"
                        colorScheme="blue"
                      />
                    </FormControl>
                  </Flex>

                  <Flex align={"center"} mb={"3"}>
                    <Text align={"left"}>{"Name"}</Text>
                    <FormControl textAlign="right" ml={"8"}>
                      <Input
                        type="text"
                        value={semester.name}
                        width={"95%"}
                        onChange={(e) =>
                          handleChangeInSemester(
                            semesterIndex,
                            "name",
                            e.target.value
                          )
                        }
                      />
                    </FormControl>
                  </Flex>
                  <Flex align={"center"} mb={"3"}>
                    <Text align={"left"}>{"Type"}</Text>
                    <FormControl textAlign="left" ml={"14"}>
                      <Select
                        width={"100%"}
                        value={semester.semesterType}
                        onChange={(e) =>
                          handleChangeInSemester(
                            semesterIndex,
                            "semesterType",
                            e.target.value
                          )
                        }
                      >
                        <option value="">{"Select Semester Type"}</option>
                        {semesterTypes.map((item, semesterIndex) => (
                          <option key={semesterIndex} value={item}>
                            {item}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>

                  <Flex align={"center"} mb={"3"}>
                    <Text align={"left"}>
                      {"Semester\u00A0Start\u00A0Date"}
                    </Text>
                    <FormControl textAlign="right">
                      <DatePicker
                        selected={semesters[semesterIndex].startDate}
                        onChange={(date) =>
                          handleChangeInSemester(
                            semesterIndex,
                            "startDate",
                            date
                          )
                        }
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select Start Date"
                        className="nice-datepicker"
                        isClearable={true}
                        showPopperArrow={true}
                        dropdownMode="select"
                        showMonthDropdown
                        showYearDropdown
                      />
                    </FormControl>
                  </Flex>
                  {!ongoing && (
                    <Flex align={"center"} mb={"3"}>
                      <React.Fragment>
                        <Text align={"left"}>
                          {"Semester\u00A0End\u00A0Date"}
                        </Text>
                        <FormControl textAlign="right">
                          <DatePicker
                            selected={
                              !ongoing ? semesters[semesterIndex].endDate : null
                            }
                            onChange={(date) =>
                              handleChangeInSemester(
                                semesterIndex,
                                "endDate",
                                date
                              )
                            }
                            dateFormat="dd/MM/yyyy"
                            placeholderText="Select End Date"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            isClearable={true}
                            showPopperArrow={true}
                            className="nice-datepicker"
                          />
                        </FormControl>
                      </React.Fragment>
                    </Flex>
                  )}

                  <Flex align={"center"} mb={"3"}>
                    <Text align={"left"} pr={"3"}>
                      {"Total\u00A0Credits"}
                    </Text>
                    <FormControl textAlign="right" ml={"1"}>
                      <Input
                        type="text"
                        placeholder="Enter a floating-point number"
                        value={semester.totalCredits}
                        onChange={(e) =>
                          handleChangeInSemester(
                            semesterIndex,
                            "totalCredits",
                            e.target.value
                          )
                        }
                        width={"100%"}
                      />
                    </FormControl>
                  </Flex>
                  <Flex align={"center"} mb={"3"}>
                    <Text align={"left"} pr={"3"}>
                      {"Obtained\u00A0SPI"}
                    </Text>
                    <FormControl textAlign="right" ml={"-1"}>
                      <Input
                        type="text"
                        placeholder="Enter a floating-point number"
                        value={!ongoing ? semester.obtainedSpi : 0}
                        isDisabled={ongoing}
                        onChange={(e) =>
                          handleChangeInSemester(
                            semesterIndex,
                            "obtainedSpi",
                            e.target.value
                          )
                        }
                        width={"100%"}
                      />
                    </FormControl>
                  </Flex>

                  {ongoing && (
                    <Flex align={"center"} mb={"3"}>
                      <Text align={"left"} pr={"8"}>
                        {"Target\u00A0SPI"}
                      </Text>
                      <FormControl textAlign="right" ml={"-1"}>
                        <Input
                          type="text"
                          placeholder="Enter a floating-point number"
                          value={ongoing ? semester.targetSpi : 0}
                          isDisabled={!ongoing}
                          onChange={(e) =>
                            handleChangeInSemester(
                              semesterIndex,
                              "targetSpi",
                              e.target.value
                            )
                          }
                          width={"100%"}
                        />
                      </FormControl>
                    </Flex>
                  )}
                  <Flex minWidth="max-content" alignItems="center" gap="2">
                    <Spacer />
                    <ButtonGroup textAlign={"right"}>
                      <Tooltip
                        placement="bottom"
                        isDisabled={semester.semesterId !== -1}
                        label="Save the semester first to edit courses"
                        aria-label="Save the semester first to edit courses"
                      >
                        <Button
                          colorScheme="blue"
                          mr={2}
                          onClick={() => {
                            toggleCoursesModal();
                            handleOpenSemesterForCoursesEdit(semester);
                          }}
                          isDisabled={semester.semesterId === -1}
                        >
                          {"Edit Courses"}
                        </Button>
                      </Tooltip>

                      <Modal
                        isOpen={isOpenModalCourses}
                        onClose={toggleCoursesModal}
                        size="6xl"
                        // isCentered
                        motionPreset="slideInBottom"
                        scrollBehavior="inside"
                        blockScrollOnMount={false}
                      >
                        <ModalOverlay />
                        <ModalContent>
                          <ModalHeader>
                            {"Edit Courses for: " +
                              semesterForCoursesEdit?.name}
                          </ModalHeader>
                          <ModalBody>
                            <Table variant="plain" colorScheme="gray">
                              <Thead>
                                <Tr>
                                  <Th width={"10%"}>{"S.No."}</Th>
                                  <Th width={"20%"}>{"Course Code"}</Th>
                                  <Th width={"55%"}>{"Course Name"}</Th>
                                  <Th width={"5%"}>{"Is Ongoing"}</Th>
                                  <Th width={"6%"}>{"Total Credits"}</Th>
                                  <Th width={"6%"}>{"Obtained Grade"}</Th>
                                  <Th width={"6%"}>{"Target Grade"}</Th>
                                  <Th width={"15%"}>{"Actions"}</Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {courses.map((course, courseIndex) => (
                                  <Tr key={courseIndex}>
                                    <Td width={"10%"}>
                                      <Input
                                        type="text"
                                        value={courseIndex + 1}
                                        disabled={true}
                                      />
                                    </Td>
                                    <Td width={"20%"}>
                                      <Input
                                        type="text"
                                        value={course.code}
                                        onChange={(e) =>
                                          handleChangeInCourse(
                                            courseIndex,
                                            "code",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Td>
                                    <Td width={"55%"}>
                                      <Input
                                        type="text"
                                        value={course.name}
                                        onChange={(e) =>
                                          handleChangeInCourse(
                                            courseIndex,
                                            "name",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Td>
                                    <Td width={"5%"}>
                                      <Switch
                                        isChecked={
                                          semesterForCoursesEdit?.ongoing
                                        }
                                        disabled
                                      />
                                    </Td>
                                    <Td width={"6%"}>
                                      <Input
                                        type="text"
                                        value={course.totalCredits}
                                        onChange={(e) =>
                                          handleChangeInCourse(
                                            courseIndex,
                                            "totalCredits",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </Td>
                                    <Td width={"6%"}>
                                      <Input
                                        type="text"
                                        value={course.obtainedGrade}
                                        onChange={(e) =>
                                          handleChangeInCourse(
                                            courseIndex,
                                            "obtainedGrade",
                                            e.target.value
                                          )
                                        }
                                        disabled={
                                          semesterForCoursesEdit?.ongoing
                                        }
                                      />
                                    </Td>
                                    <Td width={"6%"}>
                                      <Input
                                        type="text"
                                        value={course.targetGrade}
                                        onChange={(e) =>
                                          handleChangeInCourse(
                                            courseIndex,
                                            "targetGrade",
                                            e.target.value
                                          )
                                        }
                                        disabled={
                                          !semesterForCoursesEdit?.ongoing
                                        }
                                      />
                                    </Td>
                                    <Td width={"15%"}>
                                      <Flex>
                                        {course.courseIndex !== 0 && (
                                          <IconButton
                                            aria-label="Delete row"
                                            icon={<MinusIcon />}
                                            colorScheme="red"
                                            ml={5}
                                            onClick={() =>
                                              deleteCourseRow(courseIndex)
                                            }
                                          />
                                        )}
                                      </Flex>
                                    </Td>
                                  </Tr>
                                ))}
                              </Tbody>
                            </Table>
                          </ModalBody>
                          <ModalFooter>
                            <HStack justifyContent="space-between" width="full">
                              <Button
                                leftIcon={<AddIcon />}
                                aria-label="Add row"
                                icon={<AddIcon />}
                                colorScheme="green"
                                onClick={addCourseRow}
                              >
                                {"Add Course"}
                              </Button>
                              <HStack>
                                <Button
                                  colorScheme="blue"
                                  mr={3}
                                  onClick={handleCoursesSave}
                                >
                                  {"Save"}
                                </Button>
                                <Button
                                  onClick={() => {
                                    toggleCoursesModal();
                                    resetCourses();
                                  }}
                                >
                                  {"Cancel"}
                                </Button>
                              </HStack>
                            </HStack>
                          </ModalFooter>
                        </ModalContent>
                      </Modal>
                      <Button
                        colorScheme="teal"
                        onClick={() => handleSemesterSave(semesterIndex)}
                      >
                        {"Save"}
                      </Button>
                      <Button
                        colorScheme="red"
                        onClick={() => handleSemesterDeleteClick(semesterIndex)}
                      >
                        {"Delete"}
                      </Button>
                      <AlertDialog
                        isOpen={isOpenDeleteAlert}
                        leastDestructiveRef={cancelDeleteSemesterRef}
                        onClose={onCloseDeleteAlert}
                      >
                        <AlertDialogOverlay>
                          <AlertDialogContent>
                            <AlertDialogHeader fontSize="lg" fontWeight="bold">
                              {"Delete Semester"}
                            </AlertDialogHeader>

                            <AlertDialogBody>
                              You will lose all the data for this semester.
                            </AlertDialogBody>

                            <AlertDialogFooter>
                              <Button
                                ref={cancelDeleteSemesterRef}
                                onClick={onCloseDeleteAlert}
                              >
                                {"Cancel"}
                              </Button>
                              <Button
                                colorScheme="red"
                                onClick={() => deleteSemester()}
                                ml={3}
                              >
                                {"Proceed"}
                              </Button>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialogOverlay>
                      </AlertDialog>
                    </ButtonGroup>
                  </Flex>
                </form>
              </Card>
            );
          })}
          <AddSemesterEmptyCard />
        </Grid>
      )}
    </Box>
  );
};

export default SemesterForm;
