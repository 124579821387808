const keys = require("./keys");

const API_BASE_URL = keys.apiBaseUrl;
const BRANCH_URL = keys.branchUrl;
const COLLEGE_URL = keys.collegeUrl;
const COURSE_URL = keys.courseUrl;
const DEGREE_URL = keys.degreeUrl;
const EDUCATION_URL = keys.educationUrl;
const SEMESTER_URL = keys.semesterUrl;
const USER_URL = keys.userUrl;

export const ACCESS_TOKEN = "accessToken";

const OAUTH2_REDIRECT_URI = keys.redirectUri;

export const GOOGLE_AUTH_URL =
  API_BASE_URL + "/oauth2/authorize/google?redirect_uri=" + OAUTH2_REDIRECT_URI;

export const BRANCH_API_URL = API_BASE_URL + BRANCH_URL;
export const COLLEGE_API_URL = API_BASE_URL + COLLEGE_URL;
export const COURSE_API_URL = API_BASE_URL + COURSE_URL;
export const DEGREE_API_URL = API_BASE_URL + DEGREE_URL;
export const EDUCATION_API_URL = API_BASE_URL + EDUCATION_URL;
export const SEMESTER_API_URL = API_BASE_URL + SEMESTER_URL;
export const USER_API_URL = API_BASE_URL + USER_URL;
