// import
import Dashboard from "views/Dashboard/Dashboard.js";
import Profile from "views/Dashboard/Profile.js";
import GradeBreakup from "views/Dashboard/GradeBreakup";
import GradePlanner from "views/Dashboard/GradePlanner";
import Transcript from "views/Dashboard/Transcript";
import AccountSettings from "views/Dashboard/AccountSettings";

import {
  HomeIcon,
  StatsIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
} from "components/Icons/Icons";
import SignInSignUpPage from "views/Pages/SignInSignUpPage";
import { RiLoginCircleLine } from "react-icons/ri";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <HomeIcon color="inherit" />,
    component: <Dashboard />,
    layout: "/admin",
    sidebarDisplay: true,
  },
  {
    path: "/grade-breakup",
    name: "Grades Breakup",
    icon: <StatsIcon color="inherit" />,
    component: <GradeBreakup />,
    layout: "/admin",
    sidebarDisplay: true,
  },
  {
    path: "/transcript",
    name: "Transcript",
    icon: <DocumentIcon color="inherit" />,
    component: <Transcript />,
    layout: "/admin",
    sidebarDisplay: true,
  },
  {
    path: "/grade-planner",
    name: "Plan Your Grades",
    icon: <RocketIcon color="inherit" />,
    component: <GradePlanner />,
    layout: "/admin",
    sidebarDisplay: true,
  },
  {
    path: "/profile",
    name: "Profile",
    icon: <PersonIcon color="inherit" />,
    component: <Profile />,
    layout: "/admin",
    sidebarDisplay: false,
  },
  {
    path: "/settings",
    name: "Account Settings",
    icon: <DocumentIcon color="inherit" />,
    component: <AccountSettings />,
    layout: "/admin",
    sidebarDisplay: false,
  },
  {
    path: "/login",
    name: "Sign In",
    icon: <RiLoginCircleLine color="inherit" />,
    component: <SignInSignUpPage />,
    layout: "/admin",
    sidebarDisplay: false,
  },
];
export default dashRoutes;
