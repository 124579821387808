module.exports = {
  redirectUri: "http://academicxscoreqaclient.com:3002/login/oauth2/redirect",
  apiBaseUrl: "http://academicxscoreqaserver.com:8080",
  branchUrl: "/api/v1/branch",
  collegeUrl: "/api/v1/college",
  courseUrl: "/api/v1/course",
  degreeUrl: "/api/v1/degree",
  educationUrl: "/api/v1/education",
  semesterUrl: "/api/v1/semester",
  userUrl: "/api/v1/user",
};
