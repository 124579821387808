import { Alert, AlertIcon } from "@chakra-ui/react";

const ShowEmptyDataAlert = (props) => {
  return (
    <Alert status="info">
      <AlertIcon />
      {props.message || "No data to show"}
    </Alert>
  );
};

export default ShowEmptyDataAlert;
