/*eslint-disable*/
import { Flex, Link, List, ListItem, Text } from "@chakra-ui/react";
import React from "react";

export default function Footer(props) {
  return (
    <Flex
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      justifyContent="space-between"
      px="30px"
      pb="20px"
    >
      <Text
        color="gray.400"
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}
      >
        &copy; {1900 + new Date().getYear()},{" "}
        <Text as="span">Made with ❤️ by </Text>
        <Link color="blue.400" href="https://itspawanhere.com/" target="_blank">
          Pawan Kumar
        </Link>
      </Text>
      <List display="flex">
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}
        ></ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}
        >
          <Link color="gray.400" href="https://buymeacoffee.com/itspawanhere">
            Buy me a coffee
          </Link>
        </ListItem>
        <ListItem
          me={{
            base: "20px",
            md: "44px",
          }}
        >
          <Link color="gray.400" href="https://itspawanhere.com/">
            Blog
          </Link>
        </ListItem>
        <ListItem>
          <Link
            color="gray.400"
            href="http://creativecommons.org/licenses/by-nc-nd/4.0/?ref=chooser-v1"
          >
            This work is licensed under CC BY-NC-ND 4.0
          </Link>
        </ListItem>
      </List>
    </Flex>
  );
}
