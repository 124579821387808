export const utils = {
  convertDateToLocalTimeZone: (date) => {
    if (!date) return null;
    // Get the current timezone offset in hours
    const timezoneOffset = new Date().getTimezoneOffset() * 60000; // convert minutes to milliseconds
    // Adjust the date to local timezone
    const localDate = new Date(date.getTime() - timezoneOffset);
    return localDate;
  },
  // Check if a variable is an integer
  isInteger: function isInteger(variable) {
    return Number.isInteger(parseInt(variable));
  },

  // Check if a variable is a floating point number
  isFloat: function isFloat(variable) {
    return !Number.isInteger(parseInt(variable)) && !isNaN(variable);
  },

  isIntegerOrFloat: function isIntegerOrFloat(variable) {
    return typeof variable === "number";
  },

  extractHttpError: function extractHttpError(error) {
    console.error("Error", error?.message);
    return error?.message;
  },

  isValidSemester: (semester) => {
    let error = "";
    if (semester.name === "") error += "Empty Semester Name\n";
    if (semester.number === "" || semester.number === -1)
      error += "Empty Semester Number\n";
    if (
      utils.isInteger(semester.number) === false ||
      parseInt(semester.number) <= 0
    )
      error += "Invalid Semester Number\n";
    if (semester.semesterType === "") error += "Invalid Semester Type\n";
    if (semester.startDate == null) error += "Invalid Semester Start Date\n";
    if (!utils.isIntegerOrFloat(+semester.totalCredits))
      error += "Total Credits should be a number\n";
    if (+semester.totalCredits === 0.0) error += "Invalid Total Credits\n";
    if (semester.ongoing === false) {
      if (semester.endDate == null) error += "Invalid Semester End Date\n";
      if (!utils.isIntegerOrFloat(+semester.obtainedSpi))
        error += "Obtained SPI should be a number\n";
      if (+semester.obtainedSpi === 0.0) error += "Invalid Obtained SPI\n";
    }
    if (semester.ongoing === true) {
      if (!utils.isIntegerOrFloat(+semester.targetSpi))
        error += "Target SPI should be a number\n";
      if (+semester.targetSpi === 0.0) error += "Invalid Target SPI\n";
    }

    return error;
  },

  isValidCourse: (course) => {
    let error = "";
    if (course.name === "" && course.code === "")
      error += "Empty Course code and name\n";
    if (!utils.isIntegerOrFloat(+course.totalCredits))
      error += "Total Credits should be a number\n";
    if (+course.totalCredits === 0.0) error += "Invalid Total Credits\n";
    if (course.ongoing === false) {
      if (!utils.isIntegerOrFloat(+course.obtainedGrade))
        error += "Obtained Grade should be a number\n";
      if (+course.obtainedGrade === 0.0) error += "Invalid Obtained Grade\n";
    }
    if (course.ongoing === true) {
      if (!utils.isIntegerOrFloat(+course.targetGrade))
        error += "Target Grade should be a number\n";
      if (+course.targetGrade === 0.0) error += "Invalid Target Grade\n";
    }

    return error;
  },

  getTotalCredits: (semester) => {
    let totalCredits = 0;
    if (semester.courses.length > 0) {
      semester.courses.forEach((course) => {
        totalCredits += course.totalCredits;
      });
    } else totalCredits = semester.totalCredits;
    return totalCredits;
  },

  getTotalObtainedCredits: (semester) => {
    let obtainedCredits = 0;
    if (semester.courses.length > 0) {
      semester.courses.forEach((course) => {
        obtainedCredits += (course.totalCredits * course.obtainedGrade) / 10.0;
      });
    } else
      obtainedCredits = (semester.totalCredits * semester.obtainedSpi) / 10.0;
    return obtainedCredits;
  },

  getSpiForSemester: (semester, degreeGradeScale) => {
    let totalCredits = utils.getTotalCredits(semester);
    let obtainedCredits = utils.getTotalObtainedCredits(semester);
    const spi = (obtainedCredits * degreeGradeScale) / totalCredits;
    return spi.toFixed(2);
  },

  getCpiSoFarForSemester: (transcriptDataSoFar, degreeGradeScale) => {
    let totalCredits = 0;
    let obtainedCredits = 0;
    transcriptDataSoFar.forEach((semester) => {
      if (semester.courses.length === 0) {
        totalCredits += semester.totalCredits;
        obtainedCredits +=
          (semester.totalCredits * semester.obtainedSpi) / 10.0;
      } else {
        semester.courses.forEach((course) => {
          totalCredits += course.totalCredits;
          obtainedCredits +=
            (course.totalCredits * course.obtainedGrade) / 10.0;
        });
      }
    });
    const cpiSoFar = (obtainedCredits * degreeGradeScale) / totalCredits;
    return cpiSoFar.toFixed(2);
  },

  getDegreeGradeScaleNumeric: (gradeScale) => {
    if (gradeScale === "FOUR") return 4.0;
    if (gradeScale === "TEN") return 10.0;
    if (gradeScale === "HUNDRED") return 100.0;
    return 0.0;
  },
  removeDuplicates: (arr) => {
    const seen = {};
    return arr.filter((value) => {
      if (!seen[value]) {
        seen[value] = true;
        return true;
      }
      return false;
    });
  },
  fixUrl: (url) => {
    if (!/^https?:\/\//i.test(url)) {
      url = "http://" + url;
    }
    return url;
  },
  redirectToGradePlanner: () => {
    window.location.href = window.location.origin + "#/admin/grade-planner";
  },
};
