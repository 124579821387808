import axios from "axios";
import { ACCESS_TOKEN } from "config/apiUrl";
import Swal from "sweetalert2";

const callAxios = (method, url, data = null) => {
  const headers = {
    "Content-Type": "application/json",
  };

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers["Authorization"] = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;
  }

  if (method === "get") {
    return axios.get(url, { headers });
  } else if (method === "post") {
    return axios.post(url, data, { headers });
  } else if (method === "put") {
    return axios.put(url, data, { headers });
  } else if (method === "delete") {
    return axios.delete(url, { headers });
  } else {
    throw new Error("Unsupported method");
  }
};

const makeRequest = async (method, url, data = null) => {
  try {
    const response = await callAxios(method, url, data);
    return response;
  } catch (error) {
    console.error(error);
    if (error.code === "ERR_NETWORK" && error.message === "Network Error") {
      Swal.fire({
        icon: "error",
        title: "Unable to reach our servers",
        text: "Please check your internet connection and try again. If that doesn't work, drop a note to us.",
      });
    }
    if (error.response && error.response.status === 404) {
      return {
        error: "Not found",
        details: error.message,
        message: error.response.data,
        status: error.response.status,
        url,
      };
    } else if (error.response && error.response.status === 400) {
      if (error.response.data === "Access Denied") {
        window.location.href = window.location.origin + "#/login";
        return {
          error: "Access Denied",
          details: error.message,
          message: error.response.data,
          status: error.response.status,
          url,
        };
      } else
        return {
          error: "Bad Request",
          details: error.message,
          message: error.response.data,
          status: error.response.status,
          url,
        };
    } else if (error.response) {
      return {
        error: "API error",
        details: error.message,
        message: error.response.data,
        status: error.response.status,
        url,
      };
    } else {
      return {
        error: "API error",
        details: "API error",
        message: "API error",
        status: "error",
        url,
      };
    }
  }
};

export default makeRequest;
